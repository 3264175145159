import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const defaultPalette = {
  primaryColor: '#1890ff',
  errorColor: '#ff4d4f',
  warningColor: '#faad14',
  successColor: '#52c41a',
  infoColor: '#1890ff',
};

const customCSSVariables = {
  superadminColor: { varName: '--superadmin-color', defaultValue: '#00A0E9' },
  ownerColor: { varName: '--owner-color', defaultValue: '#745c97' },
  guestColor: { varName: '--guest-color', defaultValue: '#413c69' },
  fontFamily: {
    varName: 'font-family',
    defaultValue:
      'Proxima Nova,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  },
};

export const ThemeConfig = ({ children }) => {
  const { colorPalette: sitePalette, CSSVariables = {} } = useSelector(
    (state) => state?.site?.config
  );

  useEffect(() => {
    // antd's colorPalette modification
    const mergedTheme = { ...defaultPalette, ...sitePalette };
    ConfigProvider.config({
      theme: mergedTheme,
    });

    // custom CSS variables
    const body = document.querySelector('body');
    Object.entries(customCSSVariables).forEach(([key, { varName, defaultValue }]) => {
      body.style.setProperty(varName, CSSVariables[key] || defaultValue);
    });
  }, [sitePalette, CSSVariables]);
  return children;
};
