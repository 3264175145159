import * as CryptoJS from 'crypto-js';
import ecc from 'eosjs-ecc';
import * as gqlService from './graphql';
import * as auth from './auth.js';
import { JsonRpc } from 'eosjs';

import * as eosHelper from './eos-helper.js';
import * as accountNameHelper from './eosjs-account-name.js';
import * as jwtHelper from './jwt-helper';
import * as keyHelper from './eosjs-key.js';
import * as globalCfg from '@app/configs/global';

import Long from 'long';

import logger from '@app/utils/logger';

import moment from 'moment';

import * as utils from '../../utils/utils';

export { keyHelper };
export { accountNameHelper };
export { eosHelper };
export { auth };

export const calcChecksum = (file) =>
  new Promise((res, rej) => {
    var reader = new FileReader(); //define a Reader
    // var file = $("#f1")[0].files[0]; //get the File object
    if (!file) {
      rej('no file selected');
      return;
    } //check if user selected a file

    reader.onload = function (f) {
      var file_result = this.result; // this == reader, get the loaded file "result"
      var file_wordArr = CryptoJS.lib.WordArray.create(file_result); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
      var sha256_hash = CryptoJS.SHA256(file_wordArr); //calculate SHA256 hash
      console.log('Calculated SHA256:' + sha256_hash.toString()); //output result

      // let a = eosECC.sha256(JSON.stringify("Hello));
      // eosECC.sha256(Buffer.from(a, 'hex'))

      res({
        file: f,
        hash: sha256_hash.toString(),
      });
    };
    reader.readAsArrayBuffer(file); //read file as ArrayBuffer
  });

const jsonRpc = new JsonRpc(globalCfg.eos.endpoint);

export const fetchResult = async (options, limit) => {
  const mergedOptions = {
    json: true,
    limit: limit || 9999,
    encode_type: 'hex',
    ...options,
  };

  const result = await jsonRpc.get_table_rows(mergedOptions);

  return result;
};

export const fetchRows = async (options, limit) => {
  const result = await fetchResult(options, limit);

  return result.rows;
};

export const getByHash = async (hash, table) => {
  /* ************************************* */
  // byall
  // LE(0ULL, account).value, LE(provider.value, (uint64_t)service_id_num)
  // hash = `0x${hash}`;
  // hash = 'bccddf5a69b361b4c9d96df335325954795b7256ca8acbeaf91dd533ce101083';

  const hashLong = Long.fromString(hash, true, 16);
  const hashLongPlusOne = hashLong.add(1);
  let upperBound = hashLongPlusOne.toString(16);

  if (upperBound.length < hash.length)
    upperBound = `${hash.slice(0, hash.length - upperBound.length)}${upperBound}`;

  const bounds = {
    lower_bound: `${hash}`,
    upper_bound: `${upperBound}`,
  };

  const _table = table || globalCfg.pixivus.table_hashes;
  const result = await fetchResult({
    code: globalCfg.pixivus.account,
    table: _table,
    scope: globalCfg.pixivus.account,
    key_type: 'sha256',
    index_position: 2,
    ...bounds,
  });

  return result;
};

export const getKeyAccounts = (public_key) => getKeyAccountsImpl(public_key);
const getKeyAccountsImpl = async (public_key) => {
  const response = await jsonRpc.history_get_key_accounts(public_key);
  console.log(' ########## getKeyAccounts:', JSON.stringify(response));
  return response ? response.account_names : [];
};

export const togglePostVisibility = async (bucketId, transactionId, visible) => {
  const path =
    globalCfg.pixivus.rest_endpoint +
    `/buckets/${bucketId}/transactions/${transactionId}/toggleVisibility`;
  const method = 'POST';
  try {
    const data = await jwtHelper.apiCall(path, method, { visible });
    console.log(' pixivus::togglePostVisibility >> RESPONSE', JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(' pixivus::togglePostVisibility >> ERROR ', JSON.stringify(error));
  }
};

export const changeCategoryOrderOfBucket = async (bucket_id, categoryOrder) => {
  const path = globalCfg.pixivus.rest_endpoint + `/buckets/${bucket_id}/categoryOrder`;
  const method = 'PUT';
  try {
    const data = await jwtHelper.apiCall(path, method, { categoryOrder });
    console.log(' pixivus::togglePostVisibility >> RESPONSE', JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(' pixivus::togglePostVisibility >> ERROR ', JSON.stringify(error));
  }
};

export const signPost = async (post_id, signature_id, signature) =>
  new Promise((res, rej) => {
    const path = globalCfg.pixivus.rest_endpoint + `/posts/${post_id}/${signature_id}/sign`;
    const method = 'POST';
    const post_params = {
      ...signature,
    };

    jwtHelper.apiCall(path, method, post_params).then(
      (data) => {
        console.log(' pixivus::signPost >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::signPost >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const postFreeFile = async (file, hash, memo, location) =>
  new Promise((res, rej) => {
    const path = globalCfg.pixivus.rest_endpoint + '/posts/guest';
    const method = 'POST';
    const post_params = {
      file_name: file.name,
      file_type: file.type,
      metadata: `size: ${file.size} bytes`,
      hash: hash,
      memo: JSON.stringify(memo),
      location: location,
    };

    jwtHelper.apiCall(path, method, post_params).then(
      (data) => {
        console.log(' pixivus::postFreeFile >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::postFreeFile >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const createOrUpdateBucket = async (bucket) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + '/buckets';
    const path = bucket._id ? `/${bucket._id}` : '';
    const method = bucket._id ? 'PATCH' : 'POST';

    let post_params = bucket;

    jwtHelper.apiCall(domain + path, method, post_params).then(
      (data) => {
        console.log(' pixivus::createBucket >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::createBucket >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const createInvitedUser = async (user, token) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/create/invited/${token}`;
    const method = 'POST';

    let post_params = user;

    jwtHelper.apiCall(domain, method, post_params).then(
      (data) => {
        console.log(' pixivus::createInvitedUser >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::createInvitedUser >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const registerUser = async (user) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/register`;
    const method = 'POST';

    let post_params = user;

    jwtHelper.apiCall(domain, method, post_params).then(
      (data) => {
        console.log(' pixivus::registerUser >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::registerUser >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const validateIdentity = async (token, recaptcha_token) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/email_confirmation/${token}`;
    const method = 'POST';

    jwtHelper.apiCall(domain, method, { recaptcha_token: recaptcha_token }).then(
      (data) => {
        console.log(' pixivus::validateIdentity >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::validateIdentity >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const resetPassword = async (public_key, token, recaptcha_token) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/reset_password/${token}`;
    const method = 'POST';

    jwtHelper
      .apiCall(domain, method, { public_key: public_key, recaptcha_token: recaptcha_token })
      .then(
        (data) => {
          console.log(' pixivus::resetPassword >> RESPONSE', JSON.stringify(data));
          res(data);
        },
        (ex) => {
          console.log(' pixivus::resetPassword >> ERROR ', JSON.stringify(ex));
          rej(ex);
        }
      );
  });

export const getAccountNameByToken = async (token, recaptcha_token) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/account_name_by_token/${token}`;
    const method = 'POST';

    jwtHelper.apiCall(domain, method, { recaptcha_token: recaptcha_token }).then(
      (data) => {
        console.log(' pixivus::getAccountNameByToken >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::getAccountNameByToken >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const forgotPassword = async (email, recaptcha_token) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/forgot_password/${email}`;
    const method = 'POST';

    jwtHelper.apiCall(domain, method, { recaptcha_token: recaptcha_token }).then(
      (data) => {
        console.log(' pixivus::forgotPassword >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::forgotPassword >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const cancelResetPassword = async (token, recaptcha_token) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + `/users/cancel_reset_password/${token}`;
    const method = 'POST';

    jwtHelper.apiCall(domain, method, { recaptcha_token: recaptcha_token }).then(
      (data) => {
        console.log(' pixivus::cancelResetPassword >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::cancelResetPassword >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const createOrUpdateUser = async (user) =>
  new Promise((res, rej) => {
    const domain = globalCfg.pixivus.rest_endpoint + '/users';
    const path = user._id ? `/${user._id}` : '';
    const method = user._id ? 'PATCH' : 'POST';

    let post_params = user;

    jwtHelper.apiCall(domain + path, method, post_params).then(
      (data) => {
        console.log(' pixivus::createUser >> RESPONSE', JSON.stringify(data));
        res(data);
      },
      (ex) => {
        console.log(' pixivus::createUser >> ERROR ', JSON.stringify(ex));
        rej(ex);
      }
    );
  });

export const createPost = (post, file) =>
  new Promise((res, rej) => {
    const path = globalCfg.pixivus.rest_endpoint + `/posts/${post.bucket_id}`;
    const method = 'POST';
    const post_params = {
      ...post,
      file_name: file.name,
      file_type: file.type,
      metadata: `size: ${file.size} bytes`,
    };

    let formData = new FormData();
    formData.append('post', JSON.stringify(post_params));
    formData.append(globalCfg.pixivus.FILE_ATTACHMENT_KEY, file);

    const bearer_token = jwtHelper.getBearerTokenByKey();

    try {
      fetch(path, {
        // Your POST endpoint
        method: method,
        headers: {
          Authorization: bearer_token,
        },
        body: formData,
      })
        .then(
          (response) => response.json(), // if the response is a JSON object
          (ex) => {
            rej(ex);
          }
        )
        .then((success) => {
          if (!success) {
            rej('UNKNOWN ERROR!');
            return;
          } else if (success && success.error) {
            rej(success.error);
            return;
          } else if (success && success.errors) {
            rej(success.errors[0]);
            return;
          }
          console.log(success);
          res(success);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          rej(error);
        });
    } catch (e) {
      rej(e);
    }
  });

export const createMultifilePost = (posts, files) =>
  new Promise((res, rej) => {
    const path = globalCfg.pixivus.rest_endpoint + `/posts_multi/${posts[0].bucket_id}`;
    const method = 'POST';
    const post_params = posts;

    let formData = new FormData();
    formData.append('post', JSON.stringify(post_params));
    files.map((file) => formData.append(globalCfg.pixivus.FILE_ATTACHMENT_KEY, file));
    const bearer_token = jwtHelper.getBearerTokenByKey();

    try {
      fetch(path, {
        method: method,
        headers: {
          Authorization: bearer_token,
        },
        body: formData,
      })
        .then(
          (response) => response.json(),
          (ex) => {
            rej(ex);
          }
        )
        .then((success) => {
          if (!success) {
            rej('UNKNOWN ERROR!');
            return;
          } else if (success && success.error) {
            rej(success.error);
            return;
          } else if (success && success.errors) {
            rej(success.errors[0]);
            return;
          }
          console.log(success);
          res(success);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          rej(error);
        });
    } catch (e) {
      console.log(JSON.stringify(e));
      rej(e);
    }
  });

export const login = async (account_name, private_key) => {
  // 1.- Obtengo la publica de la privada.
  const pubkey = ecc.privateToPublic(private_key);

  let _auth;
  let need_creation = false;
  try {
    _auth = await auth.auth(account_name, private_key);
  } catch (ex) {
    // if(ex && ex.error && parseInt(ex.error)==404){
    //   need_creation = true;
    // }
    _auth = undefined;
    console.log(
      'PixivusApi::login ERROR >> Account is not on private servers!',
      JSON.stringify(ex)
    );
    // throw new Error('Account is not on private servers!');
    // return;
  }

  if (!_auth) {
    logger('PixivusApi::login::about to fire error #3 > Account is not a bank customer!');
    throw new Error('Por favor verificar nombre de cuenta y contraseña');
    return;
  }

  logger('PixivusApi::login::about to getprofile');
  let profile = null;
  try {
    profile = await getProfile(account_name);
    console.log('PixivusApi::login::getprofile ', JSON.stringify(profile));
  } catch (ex) {
    console.log('PixivusApi::login::getprofile ERROR', JSON.stringify(ex));
  }

  const ret = {
    profile: profile,
  };

  console.log(
    ' ============================================== PixivusApi::login >> result: ',
    JSON.stringify(ret)
  );

  return ret;
};

export const getSiteConfig = async () => {
  try {
    const endpoint = `${globalCfg.pixivus.rest_endpoint}/sites/${utils.subdomain()}`;
    const config = await jwtHelper.apiCall(endpoint, 'GET');
    return config;
  } catch (error) {
    console.log(error);
  }
};
export const getAllDomains = async () => {
  try {
    const endpoint = `${globalCfg.pixivus.rest_endpoint}/sites`;
    const config = await jwtHelper.apiCall(endpoint, 'GET');
    return config;
  } catch (error) {
    console.log(error);
  }
};

export const userByAccountName = async (account_name) =>
  gqlService.user({ account_name: account_name });
export const userById = async (id) => gqlService.user({ _id: id });
export const users = async (filter) => gqlService.users(filter);
export const bucketProfile = async (bucket_id) => gqlService.bucketProfile({ _id: bucket_id });
export const bucketRelationships = async (bucket_id) =>
  gqlService.bucketRelationships({ _id: bucket_id });
export const buckets = async (filter) => gqlService.buckets(filter);
export const posts = async (filter) => gqlService.posts(filter);
export const stats = async () => gqlService.stats();
export const transactions = async (filter) => gqlService.transactions(filter);
export const bucketTransactions = async (filter) => gqlService.bucket_transactions(filter);
export const loadAccounts = async () => gqlService.users();
export const loadAccountsForUser = async (account_name) =>
  gqlService.users_for_user({ account_name: account_name });
export const getProfile = async (account_name) =>
  gqlService.profile({ account_name: account_name });
export const profileById = async (id) => gqlService.profile({ _id: id });
export const inviteByToken = async (token) => gqlService.invite({ token: token });
export const pendingSignatures = async (account_name) =>
  gqlService.pending_signatures({ account_name: account_name });

export const subscribeToPushNotifications = (account_name, token) =>
  new Promise((res, rej) => {
    const path = globalCfg.pixivus.rest_endpoint + `/notifications/${account_name}/${token}`;
    const method = 'POST';
    console.log('PIXIVUS::subscribeToPushNotifications:', path);
    jwtHelper.apiCall(path, method).then(
      (data) => {
        console.log(' PIXIVUS::subscribeToPushNotifications >> RESPONSE', JSON.stringify(data));
        console.log('PIXIVUS::subscribeToPushNotifications: OK', data);
        res(data);
      },
      (ex) => {
        console.log(' PIXIVUS::subscribeToPushNotifications >> ERROR ', JSON.stringify(ex));
        console.log('PIXIVUS::subscribeToPushNotifications: ERROR', ex);
        res({ error: ex });
      }
    );
  });

export const formatUnix = (date, _format = 'LLLL') => {
  let my_value = date;
  if (date.toString().length == '1570910442875'.length) my_value = date / 1000;
  return moment.unix(my_value).format(_format);
};

export const formatUnix2 = (date, _format = 'YYYY-MM-DD HH:mm:ss') => {
  let my_value = date;
  if (date.toString().length == '1570910442875'.length) my_value = date / 1000;
  return moment.unix(my_value).format(_format);
};

export const unixFromNow = (date) => {
  let my_value = date;
  if (date.toString().length == '1570910442875'.length) my_value = date / 1000;
  const m_date = moment.unix(my_value).local();

  return m_date.fromNow();
};

export const unixDateToLocale = (date) => {
  let my_value = date;
  if (date.toString().length == '1570910442875'.length) my_value = date / 1000;
  const m_date = moment.unix(my_value).local();

  if (moment().diff(m_date, 'days') < 1) return m_date.format('YYYY-MM-DD HH:mm:ss');
  return m_date.fromNow();
};

export const slugify = (text) => {
  if (!text) return '';
  return text.toLowerCase().latinise();
};
