import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login';

import BucketsFilter from '@app/components/buckets/filter';
import InfiniteScroll from 'react-infinite-scroller';
import BucketList, { VIEW_MODE_LIST, VIEW_MODE_CARD } from '@app/components/buckets/list';

import { Modal } from 'antd';
import BucketsMobileHeader from '@app/components/buckets/mobile_header';

import { injectIntl } from 'react-intl';

const BucketListWidget = (props) => {
  const [buckets, setBuckets] = useState(props.buckets);
  const [filter, setFilter] = useState(props.filter);
  const [table_ref, setTableRef] = useState(null);
  const [hide_filter, setHideFilter] = useState(props.hide_filter);
  const [loading, setLoading] = useState(props.loading);

  const [filterModalShown, setFilterModalShown] = useState(false);
  const default_view_mode = props.isMobile ? VIEW_MODE_CARD : VIEW_MODE_LIST;
  const [view_mode, setViewMode] = useState(default_view_mode);

  const getModal = () => {
    if (!filterModalShown) return null;

    return (
      <Modal
        key="filter_modal"
        title={''}
        onCancel={() => setFilterModalShown(false)}
        visible={true}
        footer={null}
      >
        <BucketsFilter filter={props.filter} callback={filterCallback} as_page={true} />
      </Modal>
    );
  };

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setBuckets(props.buckets);
  }, [props.buckets]);

  useEffect(() => {
    setHideFilter(props.hide_filter);
  }, [props.hide_filter]);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  var t_id2 = null;
  const filterCallback = (error, cancel, values, refresh) => {
    setFilterModalShown(false);

    if (cancel || error) {
      return;
    }

    if (refresh && table_ref) {
      clearTimeout(t_id2);
      t_id2 = setTimeout(() => {
        // table_ref.refresh()
        if (typeof props.refresh_callback == 'function') {
          props.refresh_callback();
        }
      }, 100);

      return;
    }

    if (values !== undefined) {
      clearTimeout(t_id2);
      t_id2 = setTimeout(() => {
        if (typeof props.filter_callback == 'function') {
          props.filter_callback({ ...filter, ...values });
        }
      }, 100);
    }
  };

  var t_id = null;
  const onBucketClick = (type, item) => {
    if (!props.callback || typeof props.callback !== 'function') return;
    clearTimeout(t_id);
    t_id = setTimeout(() => {
      props.callback(type, item);
    }, 100);
  };

  const handleInfiniteOnLoad = () => {
    if (props.loadMoreCallback) props.loadMoreCallback();
  };

  const mobileHeaderCallback = (view_mode, search_click, filter_click) => {
    console.log(' **** LIST_WIDGET::CALLBACK:', view_mode);
    if (view_mode) setViewMode(view_mode);
    if (search_click) {
    }
    if (filter_click) {
      setFilterModalShown(!filterModalShown);
    }
  };

  const bucketList = (
    <BucketList
      view_mode={view_mode}
      buckets={buckets}
      bucketType={props.bucketType}
      loading={loading}
      callback={onBucketClick}
      loadMoreCallback={props.loadMoreCallback}
    />
  );

  const bucketsFilter = props.isMobile ? (
    <BucketsMobileHeader callback={mobileHeaderCallback} />
  ) : (
    <BucketsFilter callback={filterCallback} />
  );

  const modal = getModal();

  return (
    <>
      {modal}

      {!hide_filter && bucketsFilter}

      <div className="something">
        {props.isMobile && (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loading}
            useWindow={false}
          >
            {bucketList}
          </InfiniteScroll>
        )}
        {!props.isMobile && bucketList}
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    isMobile: menuRedux.isMobile(state),
    accountName: loginRedux.accountName(state),
    isAdmin: loginRedux.isAdmin(state),
  }),
  (dispatch) => ({})
)(injectIntl(BucketListWidget));
