import antdEn from 'antd/lib/locale-provider/en_US';
// import enMessages from '../locales/en_US.json';

const importMessages = async () => {
  let messages = await import('../locales/en_US.json');
  return messages;
};

const EnLang = {
  getMessages: importMessages,
  messages: {},
  antd: antdEn,
  locale: 'en-US',
  // data: appLocaleData,
  title: 'English',
};
export default EnLang;
