import React, { useEffect, useState, Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as utils from '@app/utils/utils';

import * as loginRedux from '@app/redux/models/login';
import * as menuRedux from '@app/redux/models/menu';
import { injectIntl } from 'react-intl';

import * as pixivus from '@app/services/pixivus';

import * as icon_helper from '@app/components/posts/icon_helper';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { ProfileOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { Card, Tooltip, Tag, Spin, List, Button } from 'antd';
import { ResizeableTable } from '@app/components/widgets/resizable_columns';
import * as globalCfg from '@app/configs/global';
import TagsViewer from '@app/components/tagsviewer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseMemo } from '../../utils/utils';

export const VIEW_MODE_LIST = 'list';
export const VIEW_MODE_CARD = 'card';
export const VIEW_MODE_MAP = 'map';

const PostList = (props) => {
  const default_view_mode = props.view_mode || props.isMobile ? VIEW_MODE_CARD : VIEW_MODE_LIST;
  const [view_mode, setViewMode] = useState(default_view_mode);

  const [posts, setPosts] = useState(props.posts);
  const [loading, setLoading] = useState(props.loading);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [header, setHeader] = useState(null);
  const [intl, setIntl] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 0, total: 0 });

  useEffect(() => {
    const x = (posts || []).length;
    setPagination({ pageSize: x, total: x });
  }, [posts]);

  const can_get_more = true;

  useEffect(() => {
    const { formatMessage } = props.intl;
    let _intl = {
      form: {
        categories: {},
      },
    };
    _intl.column_title_timestamp = formatMessage({ id: 'pages.home.column_title_timestamp' });
    _intl.column_title_hash = formatMessage({ id: 'pages.home.column_title_hash' });
    _intl.column_title_file = formatMessage({ id: 'pages.home.column_title_file' });
    _intl.column_title_file_name = formatMessage({ id: 'pages.home.column_title_filename' });
    _intl.action_button_view_post = formatMessage({ id: 'pages.home.action_button_view_post' });
    _intl.action_button_view_transaction = formatMessage({
      id: 'pages.home.action_button_view_transaction',
    });
    _intl.column_title_created_at = formatMessage({ id: 'pages.home.column_title_created_at' });
    _intl.certified_at = formatMessage({ id: 'pages.post.certified_at' });
    _intl.created_by_simple = formatMessage({ id: 'global.created_by_simple' });

    _intl.column_bucket_name = formatMessage({ id: 'pages.certifications.column.bucket_name' });
    _intl.column_bucket_category = formatMessage({
      id: 'pages.certifications.column.bucket_category',
    });
    _intl.column_created_at = formatMessage({ id: 'pages.certifications.column.created_at' });
    _intl.column_file_count = formatMessage({ id: 'pages.certifications.column.file_count' });
    _intl.column_tags = formatMessage({ id: 'pages.certifications.column.tags' });
    _intl.column_address = formatMessage({ id: 'pages.certifications.column.address' });
    _intl.column_location = formatMessage({ id: 'pages.certifications.column.location' });

    _intl.load_more = formatMessage({ id: 'global.list.load_more' });
    _intl.form.tooltip = formatMessage({ id: 'global.form.tooltip' });
    _intl.categories = formatMessage({ id: 'global.categories' });

    setIntl(_intl);
  }, []);

  useEffect(() => {
    if (props.view_mode) setViewMode(props.view_mode);
  }, [props.view_mode]);

  useEffect(() => {
    const _header = calcHeader();
    setHeader(_header);
  }, [intl, isMobile]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setPosts(props.posts);
  }, [props.posts]);

  useEffect(() => {
    setIsMobile(props.isMobile);
  }, [props.isMobile]);

  const calcHeader = () => {
    const logged = isLogged() ? 'logged' : 'not_logged';
    return isMobile == true ? null : (
      <div className={`post table_view at_header ${logged}`}>
        <div className="file">{intl.column_title_file_name}</div>
        <div className="timestamp">{intl.column_title_timestamp}</div>
        <div className="hash">{intl.column_title_hash}</div>
        <div className="action">&nbsp;</div>
      </div>
    );
    // `
  };

  const getTxLink = (item, text, with_left_margin) => {
    const _classname = with_left_margin ? 'link_withMargin_left_8' : '';
    // return (<a className={_classname} href={globalCfg.eos.getBlockExplorerTxLink(item.tx_id)} target="_blank"><Icon type="safety-certificate" />&nbsp;{text}</a>);
    return (
      <Button
        type="link"
        key={`link_${item._id}`}
        size="small"
        icon={<SafetyCertificateOutlined />}
        href={globalCfg.eos.getBlockExplorerTxLink(item.tx_id)}
      >
        {text}
      </Button>
    );
    //
  };

  const getPostLink = (item) => {
    return (
      <Button
        type="link"
        key={`post_${item._id}`}
        size="small"
        onClick={() => onCallback(item)}
        title={`ID: ${item._id}`}
        icon={<ProfileOutlined />}
      >
        {intl.action_button_view_post}
      </Button>
    );
    // `
  };

  const onCallback = (item) => {
    if (typeof props.callback == 'function') props.callback(item);
  };

  //
  const fireLoadMoreCallback = () => {
    console.log('++++fireLoadMoreCallback');
    if (!props.loadMoreCallback || typeof props.loadMoreCallback != 'function') return;
    console.log('+!+!+!+!fireLoadMoreCallback');
    props.loadMoreCallback(pagination);
  };
  //
  const renderFooter = () => {
    return (
      <Button
        key={'load-more-data_'}
        disabled={!can_get_more}
        onClick={() => fireLoadMoreCallback()}
      >
        {intl.load_more}
      </Button>
    );
  };
  //
  const columns = () => [
    {
      title: intl.column_bucket_name,
      key: 'bucket.name',
      width: 50,
      align: 'left',
      render: (data, record) => {
        const cat =
          (record.bucket && record.bucket.category && record.bucket.category.name) ||
          record.category;
        const bucket_name = record.bucket && record.bucket.name;
        return (
          <div>
            <div className="certificate_name"> {bucket_name} </div>
            <div className="certificate_extra">
              {cat && (
                <div className="certificate_category">
                  {' '}
                  <span className="certificate_category_describer">
                    {intl.column_bucket_category}:
                  </span>{' '}
                  {cat}{' '}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    //
    {
      title: 'Info',
      key: 'title',
      width: 100,
      align: 'left',
      render: (data, record) => {
        const title = record.title;
        const signature =
          record.numSignatures || record.numInvites ? (
            <div className="certificate_signature_icon">
              <FontAwesomeIcon icon={'file-signature'} title="Tiene solicitud de firmas" />
            </div>
          ) : null;
        return (
          <div>
            <div className="certificate_name"> {title} </div>
            <div className="certificate_extra">{signature}</div>
          </div>
        );
      },
    },
    {
      title: intl.column_created_at,
      dataIndex: 'created_at',
      key: 'created_at',
      width: 50,
      align: 'left',
      render: (data, record) => (
        <Tooltip title={pixivus.formatUnix2(record.created_at || 0)}>
          <span>{pixivus.unixDateToLocale(record.created_at || 0)}</span>
        </Tooltip>
      ),
    },
    {
      title: intl.column_tags,
      dataIndex: 'tags',
      key: 'tags',
      width: 50,
      align: 'left',
      render: (data, record) => <TagsViewer item={record} />,
    },
    {
      title: intl.column_file_count,
      dataIndex: 'numFiles',
      key: 'numFiles',
      width: 50,
      align: 'right',
      render: (data, record) => record.numFiles,
    },
    {
      title: intl.column_address,
      key: 'geo.address',
      width: 100,
      align: 'left',
      render: (data, record) => record.geo && record.geo.address,
    },
    // {
    //   title:        intl.column_location
    //   , key:        'geo.dms'
    //   , width:      50
    //   , align:      'left'
    //   , render : (data, record) => record.geo && record.geo.dms
    // },
    {
      dataIndex: '_id',
      key: '_id',
      width: 75,
      align: 'left',
      render: (data, record) => (
        <>
          {getPostLink(record)}
          {getTxLink(record, intl.action_button_view_transaction, true)}
        </>
      ),
    },
  ];
  //
  const rowCallback = (item) => onCallback(item);

  const isLogged = () => ![null, ''].includes(props.accountName);

  const renderItem = (item) => {
    if (isLogged()) return renderItem4Logged(item);
    return renderItem4Guest(item);
  };

  const renderItem4Logged = (item) => {
    if (isMobile)
      return (
        <List.Item key={item._id} className="post">
          <Card title={item.bucket && item.bucket.name} className="post_content_wrapper">
            <div className="key_value">
              <div className="key">{intl.column_bucket_category}:</div>
              <div className="value">{item.category}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.column_created_at}:</div>
              <div className="value">{pixivus.formatUnix2(item.created_at || 0)}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.column_file_count}:</div>
              <div className="value">{item.numFiles}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.column_address}:</div>
              <div className="value">{item.geo && item.geo.address}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.column_location}:</div>
              <div className="value">{item.geo && item.geo.dms}</div>
            </div>
            <div className="key_value">
              <div className="key">&nbsp;</div>
              <div className="value buttons">
                <TagsViewer item={item} />
              </div>
            </div>
            <div className="key_value">
              <div className="value buttons">
                {getPostLink(item)} {getTxLink(item, intl.action_button_view_transaction, true)}
              </div>
            </div>
          </Card>
        </List.Item>
      );
    //

    const parsedMemo = parseMemo(item.memo);
    const { formatMessage } = props.intl;
    return (
      <List.Item key={item._id}>
        <div className="post_content_wrapper">
          <div className="post_ex">
            <div className="first_box">
              <div className="icon_numFiles">
                <LegacyIcon type={icon_helper.mimeToIcon(item.file_type)} />
                <span className="numFiles">
                  {formatMessage(
                    { id: 'pages.home.num_files' },
                    { numFiles: item.numFiles, bold: (str) => <b key={Math.random()}>{str}</b> }
                  )}
                </span>
              </div>
              <span>{pixivus.unixDateToLocale(item.block_timestamp || 0)}</span>
            </div>
            <div className="second_box">
              <div className="header">
                {item.category} @ {item.bucket ? item.bucket.name : ''}{' '}
              </div>
              <div className="content">
                {parsedMemo && !parsedMemo.isArray && (
                  <span className="main">{parsedMemo.content}</span>
                )}
                <span className="secondary">
                  {item.tags &&
                    item.tags.map((tag, idx) => (
                      <Tag key={item._id + '_tag_' + tag} color={item.tags_colors[idx]}>
                        {tag}
                      </Tag>
                    ))}
                </span>
              </div>
              <div className="footer">Hash:&nbsp;{item.hash}</div>
            </div>
            <div className="third_box">
              <div className="dms">{item.geo && item.geo.dms}</div>
              <div className="lat_lon">{item.location.join(', ')}</div>
              <div className="address">{item.geo && item.geo.address}</div>
            </div>
            {parsedMemo && parsedMemo.isArray && (
              <div className="third_box">
                {Object.entries(parsedMemo.content).map(([key, value]) => (
                  <>
                    <div className="dms">{formatMessage({ id: `global.post.memo.${key}` })}</div>
                    <p>{value}</p>
                  </>
                ))}
              </div>
            )}
            <div className="action">
              {getPostLink(item)}
              {getTxLink(item, intl.action_button_view_transaction, true)}
              <div className="author">
                {intl.created_by_simple}&nbsp;{item.user && item.user.first_name}{' '}
                {item.user && item.user.last_name}
              </div>
            </div>
          </div>
        </div>
      </List.Item>
    );

    //
  };

  const renderItem4Guest = (item) => {
    if (isMobile)
      return (
        <List.Item key={item._id} className="post">
          <div className="post_content_wrapper">
            {intl.column_title_file}:&nbsp;<b>{item.file_name}</b> (<b>{item.file_type}</b>)<br />
            {intl.column_title_hash}:&nbsp;<b>{item.hash}</b>
            <br />
            {intl.certified_at}:&nbsp;
            <span className="created_at">
              <b>{pixivus.unixDateToLocale(item.block_timestamp || 0)}</b>
            </span>
            <br />
            <div className="post_actions">
              {getPostLink(item)}
              {getTxLink(item, intl.action_button_view_transaction, true)}
            </div>
          </div>
        </List.Item>
      );
    //

    return (
      <List.Item key={item._id} className="post table_view">
        <div className="file">
          {item.file_name} {item.file_type}
        </div>
        <div className="timestamp">{pixivus.unixDateToLocale(item.block_timestamp || 0)}</div>
        <div className="hash hash_content">Hash:&nbsp;{item.hash}</div>
        <div className="action">
          {getPostLink(item)}
          {getTxLink(item, intl.action_button_view_transaction, true)}
        </div>
      </List.Item>
    );
    //
  };

  if (!isLogged() || view_mode === VIEW_MODE_CARD) {
    return (
      <Spin spinning={loading} delay={50} tip="Loading">
        <List header={header} dataSource={posts} renderItem={renderItem}></List>
      </Spin>
    );
  }

  return (
    <ResizeableTable
      key={'table__transactions'}
      rowKey={(record) => record._id}
      loading={loading}
      columns_def={columns()}
      dataSource={posts}
      footer={() => renderFooter()}
      pagination={pagination}
      scroll={{ x: 950 }}
      rowClassName={(record, rowIndex) => {
        if (record.unconfirmed == true) return 'unconfirmed';
        return rowIndex % 2 == 0 ? 'even' : 'odd';
      }}
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: (event) => {
            rowCallback && rowCallback(record);
          },
        };
      }}
    />
  );
};

export default connect(
  (state) => ({
    isMobile: menuRedux.isMobile(state),
    accountName: loginRedux.accountName(state),
  }),
  (dispatch) => ({})
)(injectIntl(PostList));
