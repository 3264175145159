import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@app/redux/configureStore';
import { DashboardRouter } from '@app/providers/router';
import routes from '@app/configs/routes';

import * as auth from '@app/services/pixivus/auth';

import './fonts.less';
import './App.css';
import './App.less';

import * as storageService from '@app/services/localStorage';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGlobe,
  faLongArrowAltRight,
  faHatWizard,
  faSignature,
  faCheckDouble,
  faMobileAlt,
  faPaperclip,
  faCommentDots,
  faComments,
  faUserAstronaut,
  faUsers,
  faArchive,
  faBoxOpen,
  faBox,
  faBoxes,
  faStamp,
  faCloudUploadAlt,
  faFile,
  faHandHoldingUsd,
  faFileWord,
  faFileExcel,
  faBan,
  faExclamationTriangle,
  faMinusCircle,
  faComment,
  faFileSignature,
  faUserMinus,
  faUserPlus,
  faCalendarAlt,
  faCalculator,
  faShapes,
  faMoneyCheckAlt,
  faTrafficLight,
  faPiggyBank,
  faKeyboard,
  faKey,
  faExclamationCircle,
  faShieldAlt,
  faUserShield,
  faExternalLinkAlt,
  faQuestionCircle,
  faMagic,
  faCreditCard,
  faPaperPlane,
  faShoppingBag,
  faStore,
  faExchangeAlt,
  faArrowUp,
  faArrowDown,
  faArrowAltCircleUp,
  faUniversity,
  faPhone,
  faMapMarkerAlt,
  faIdCard,
  faUser,
  faUserCircle,
  faDollarSign,
  faEnvelope,
  faFlagCheckered,
  faUserClock,
  faCloud,
  faFilePdf,
  faTruckMoving,
  faFileInvoice,
  faFileInvoiceDollar,
  faReceipt,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { I18nProvider } from './lang/I18nProvider';
import { ThemeConfig } from './theme/ThemeConfig';

const App = () => {
  const is_auth = auth.isAuth() === true;
  const isAuthHack = is_auth ? { isAuthHack: true } : { isAuthHack: false };
  if (!is_auth) {
    storageService.setAccountData(null);
  }

  library.add(
    far,
    fab,
    faGlobe,
    faLongArrowAltRight,
    faHatWizard,
    faSignature,
    faCheckDouble,
    faMobileAlt,
    faCommentDots,
    faComments,
    faPaperclip,
    faUserAstronaut,
    faUsers,
    faArchive,
    faBoxOpen,
    faBox,
    faBoxes,
    faStamp,
    faCloudUploadAlt,
    faFile,
    faHandHoldingUsd,
    faFileWord,
    faFileExcel,
    faBan,
    faExclamationTriangle,
    faComment,
    faMinusCircle,
    faFileSignature,
    faUserMinus,
    faUserPlus,
    faCalculator,
    faCalendarAlt,
    faShapes,
    faTrafficLight,
    faMoneyCheckAlt,
    faPiggyBank,
    faKey,
    faKeyboard,
    faExclamationCircle,
    faShieldAlt,
    faUserShield,
    faExternalLinkAlt,
    faQuestionCircle,
    faMagic,
    faCreditCard,
    faPaperPlane,
    faShoppingBag,
    faStore,
    faExchangeAlt,
    faArrowUp,
    faArrowDown,
    faArrowAltCircleUp,
    faUniversity,
    faMapMarkerAlt,
    faPhone,
    faIdCard,
    faUser,
    faUserCircle,
    faDollarSign,
    faEnvelope,
    faFlagCheckered,
    faUserClock,
    faCloud,
    faTruckMoving,
    faFileInvoiceDollar,
    faFileInvoice,
    faReceipt,
    faFilePdf,
    faChevronRight
  );

  return (
    <div className="App">
      <ReduxProvider store={store}>
        <I18nProvider>
          <ThemeConfig>
            <DashboardRouter routes={routes} {...isAuthHack} />
          </ThemeConfig>
        </I18nProvider>
      </ReduxProvider>
    </div>
  );
};

export default App;
