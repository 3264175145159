const config = {
  options : [
  {
    name                : 'Solicitud de firma digital'
    , description       : 'Solicitud de firma digital'
    , hint              : 'Se solicita la firma digital para reconocer el documento.'
    , message           : 'ha solicitado su firma digital para reconocer el documento.'
    , key               : 'simple_signature'
    , icon              : 'file-signature'
    , actions           : [
      {
        value           : '1'
        , text          : 'Firmar'
        , icon          : 'edit'
        , classname     : 'sign'

      }
    ]
  },
  {
    name                : 'Solicitud de aprobación'
    , description       : 'Solicitud de aprobación'
    , key               : 'approval_signature'
    , hint              : 'Se solicita la firma digital para aprobar o rechazar el documento.'
    , message           : 'ha solicitado su firma digital para aprobar o rechazar el documento.'
    , icon              : ['far', 'thumbs-up']
    , actions           : [
      {
        value           : '1'
        , text          : 'Aprobar'
        , icon          : 'check'
        , classname     : 'approve'
      },
      {
        value           : '0'
        , text          : 'Rechazar'
        , icon          : 'close'
        , classname     : 'reject'
      }
    ]
  }]

  , invite_states: {
    pending:{
      icon:          'question'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#faad14'
      , text:        'En proceso'
    }
    , sent   :     {
      icon:          'paper-plane'
      , icon_type:   'fontawesome'
      , color:       '#fff'
      , bg_color:    '#1890FF'
      , text:        'Enviado'
    }
    , ok : {
      icon:          'check'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#52C41A'
      , text:        'Ok'
    }
    , canceled : {
      icon:          'close'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#F5222D'
      , text:        'Cancelado'
    }
  }
  , signature_states : {
    error :     {
      icon:          'question'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#faad14'
      , text:        'N/D'
    }
    , pending   :     {
      icon:          'hourglass'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#d9d9d9'
      , text:        'Envío pendiente'
    }
    , sent   :     {
      icon:          'paper-plane'
      , icon_type:   'fontawesome'
      , color:       '#fff'
      , bg_color:    '#1890FF'
      , text:        'Enviado'
    }
    , read :     {
      icon:          'check-double'
      , icon_type:   'fontawesome'
      , color:       '#fff'
      , bg_color:    '#FA8C16'
      , text:        'Leído'
    }
    , signed :   {
      icon:          'signature'
      , icon_type:   'fontawesome'
      , color:       '#fff'
      , bg_color:    '#1890FF'
      , text:        'Firmado'
    }
    , approved : {
      icon:          'check'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#52C41A'
      , text:        'Aceptado'
    }
    , rejected : {
      icon:          'close'
      , icon_type:   'ant'
      , color:       '#fff'
      , bg_color:    '#F5222D'
      , text:        'Rechazado'
    }
  }

} ;

const INVITE_STATE_PENDING      = 'pending';
const INVITE_STATE_SENT         = 'sent';
const INVITE_STATE_OK           = 'ok';
const INVITE_STATE_CANCELED     = 'canceled';

const SIGNATURE_STATE_ERROR        = 'error';
const SIGNATURE_STATE_PENDING      = 'pending';
const SIGNATURE_STATE_SENT         = 'sent';
const SIGNATURE_STATE_READ         = 'read';
const SIGNATURE_STATE_SIGNED       = 'signed';
const SIGNATURE_STATE_APPROVED     = 'approved';
const SIGNATURE_STATE_REJECTED     = 'rejected';

const SIGNATURE_OPTION_FALSE       = 0;
const SIGNATURE_OPTION_TRUE        = 1;

const SIGNATURE_TYPE_SIMPLE_DESC   = 'simple_signature';
const SIGNATURE_TYPE_APPROVAL_DESC = 'approval_signature';

const SIGNATURE_TYPE_SIMPLE        = 0;
const SIGNATURE_TYPE_APPROVAL      = 1;

const signatureState = (signature) => {

  // console.log('---signatureConfig::', JSON.stringify(signature));

  if(!signature)
    return SIGNATURE_STATE_ERROR;
  if([SIGNATURE_STATE_PENDING, SIGNATURE_STATE_SENT, SIGNATURE_STATE_READ].includes(signature.state))
    return signature.state;
  if([SIGNATURE_TYPE_SIMPLE_DESC, SIGNATURE_TYPE_SIMPLE.toString()].includes(signature.signature_type.toString()))
    return SIGNATURE_STATE_SIGNED;
  if(signature.signature_option == SIGNATURE_OPTION_TRUE)
    return SIGNATURE_STATE_APPROVED;
  return SIGNATURE_STATE_REJECTED;
}

const getMessage = (signature) => {
  const signature_config = config.options.find(obj => obj.key==signature.signature_type);
  const requestor        = `${signature.created_by && signature.created_by.first_name } ${signature.created_by && signature.created_by.last_name}`;
  return {
    ...signature_config
    , title          : signature_config.name
    , description  : `${requestor} ${signature_config.message}`
  };

}
const filterInvites = (invites) => invites.filter(invite=>[INVITE_STATE_PENDING, INVITE_STATE_SENT].includes(invite.state))

export { config, signatureState, filterInvites, getMessage};