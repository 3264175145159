import * as routes_config from '@app/configs/routes';
import * as utils from '@app/utils/utils';

const routes = {
  guest: {
    items: [
      {
        key: routes_config.pathNames.commonMyBuckets,
        title: `menu.item.buckets`,
        path: routes_config.pathNames.commonMyBuckets,
        icon: 'container',
        permission: '*',
        bottom_divider: true,
      },
      {
        icon: 'unordered-list',
        key: routes_config.pathNames.commonTransactions,
        path: routes_config.pathNames.commonTransactions,
        title: `menu.item.recent_certifications`,
        permission: '*',
      },
      {
        key: routes_config.pathNames.commonCreateCertificate,
        path: routes_config.pathNames.commonCreateCertificate,
        title: `menu.item.new_certification`,
        icon: 'file-protect',
        permission: '*',
      },
    ],
  },
  owner: {
    items: [
      {
        icon: 'unordered-list',
        key: routes_config.pathNames.commonTransactions,
        path: routes_config.pathNames.commonTransactions,
        title: `menu.item.recent_certifications`,
        permission: '*',
      },
      {
        key: routes_config.pathNames.commonCreateCertificate,
        path: routes_config.pathNames.commonCreateCertificate,
        title: `menu.item.new_certification`,
        icon: 'file-protect',
        permission: '*',
        bottom_divider: true,
      },
      {
        key: routes_config.pathNames.commonUserList,
        path: routes_config.pathNames.commonUserList,
        title: `menu.item.users_list`,
        icon: 'user',
        permission: '*',
      },
      {
        key: routes_config.pathNames.commonCreateUser,
        path: routes_config.pathNames.commonCreateUser,
        title: `menu.item.create_user`,
        icon: 'user-add',
        permission: '*',
      },
    ],
  },
  superadmin: {
    items: [
      {
        key: routes_config.pathNames.commonDashboard,
        title: 'menu.item.dashboard',
        path: routes_config.pathNames.commonDashboard,
        icon: 'dashboard',
        permission: '*',
        bottom_divider: true,
      },
      {
        icon: 'unordered-list',
        permission: 'superadmin',
        key: routes_config.pathNames.commonTransactions,
        path: routes_config.pathNames.commonTransactions,
        title: `menu.item.recent_certifications`,
      },
      {
        key: routes_config.pathNames.commonCreateCertificate,
        path: routes_config.pathNames.commonCreateCertificate,
        title: `menu.item.new_certification`,
        icon: 'file-protect',
        permission: 'superadmin',
        bottom_divider: true,
      },
      {
        key: routes_config.pathNames.commonUserList,
        path: routes_config.pathNames.commonUserList,
        title: `menu.item.users_list`,
        icon: 'user',
        permission: 'superadmin',
      },
      {
        key: routes_config.pathNames.commonCreateUser,
        path: routes_config.pathNames.commonCreateUser,
        title: `menu.item.create_user`,
        icon: 'user-add',
        permission: 'superadmin',
      },
    ],
  },
};

export const getRoutesByAccountType = (account_type) => {
  return account_type ? routes[account_type] : [];
};

export const getRootKeys = (area) => {
  if (!area) return [];
  return routes[area].items.map((item) => item.key);
};

export const breadcrumbForPaths = (fullpaths, include_root) => {
  const my_fullpaths = Array.isArray(fullpaths) ? fullpaths : [fullpaths];

  const breadcrumbs = my_fullpaths
    .filter((fullpath) => fullpath)
    .map((fullpath) => {
      if (!fullpath) return false;
      const path_parts = fullpath.split('/');
      const area = path_parts[1];
      const path = path_parts[2];
      const menuItem = routes_config.getItemByFullpath(area, path, null);

      return {
        original_path: menuItem.path,
        path: fullpath,
        breadcrumbName: menuItem.title,
      };
    });

  if (include_root === false) return breadcrumbs;
  return [
    {
      path: '/',
      breadcrumbName: 'Inicio',
    },
    ...breadcrumbs,
  ];
};

export const breadcrumbForFile = (file) => {
  const menuItem = routes_config.getItem(file);

  return [
    {
      path: 'dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: menuItem.fullpath,
      breadcrumbName: menuItem.title,
    },
  ];
};
