import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'antd';
import './styles.css';

const baseUrls = {
  termsAndCond: 'https://www.iubenda.com/api/terms-and-conditions/',
  privacyPolicy: 'https://www.iubenda.com/api/privacy-policy/',
};

export const Policy = ({ privacyPolicyId, cookiePolicyId, termsAndCondId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [contentEndpoint, setContentEndpoint] = useState('');

  const handleClick = (endpoint) => {
    setContentEndpoint(endpoint);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setContentEndpoint('');
    setModalContent('');
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!contentEndpoint) {
      return;
    }

    const getContent = async () => {
      try {
        const response = await fetch(contentEndpoint);
        const data = await response.json();
        if (data.success) {
          setModalContent(data.content);
        }
      } catch (error) {
        handleCloseModal();
      }
    };
    getContent();
  }, [contentEndpoint]);

  return (
    <>
      <Modal visible={isModalVisible} onCancel={handleCloseModal} width={768} footer={null}>
        <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
      </Modal>
      {!!termsAndCondId && (
        <Button
          type="link"
          onClick={() => handleClick(`${baseUrls.termsAndCond}${termsAndCondId}`)}
        >
          <FormattedMessage id="app.terms_and_conditions" />
        </Button>
      )}
      {!!privacyPolicyId && (
        <Button
          type="link"
          onClick={() => handleClick(`${baseUrls.privacyPolicy}${privacyPolicyId}`)}
        >
          <FormattedMessage id="app.privacy_policy" />
        </Button>
      )}
      {!!cookiePolicyId && (
        <Button
          type="link"
          onClick={() => handleClick(`${baseUrls.privacyPolicy}${cookiePolicyId}/cookie-policy`)}
        >
          <FormattedMessage id="app.cookie_policy" />
        </Button>
      )}
    </>
  );
};
