import React, { useState, useEffect, useMemo } from 'react';
import { Layout, BackTop } from 'antd';

import PixivusHeader from '@app/components/Header';

import * as menuRedux from '@app/redux/models/menu'
import * as loginRedux from '@app/redux/models/login'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import MenuAccountView from '@app/components/Views/account_menu'

import * as utils from '@app/utils/utils';

import * as globalCfg from '@app/configs/global';
import InjectMessage from "@app/components/intl-messages";
import * as storage from '@app/services/localStorage'
import PixiFooter from '@app/components/footer';
import { useSelector } from 'react-redux';

const { Header, Content, Footer, Sider } = Layout;

const _DashboardContainer = ({footerText,  TopMenu, Menu, Children, item, menuIsCollapsed, accountName, isAuth, accountType, accountProfile
                              , logout, collapseMenu, isMobile}) => {
    
    const logoUrl = useSelector((state)=>state?.site?.config?.logo);
    const [menu_is_collapsed, setMenuIsCollapsed] = useState(menuIsCollapsed);
    
    useEffect(() => {
      setMenuIsCollapsed(menuIsCollapsed);
    }, [menuIsCollapsed])

    useEffect(() => {
      const build_version     = globalCfg.version;
      const _default_version  = '_default_version_';
      const storage_version   = storage.getVersion(_default_version);
      if(build_version==storage_version)
        return;
      if(build_version==_default_version && build_version.startsWith('1.0.'))
      {
        logout()
        return;
      }
      if(build_version!=storage_version)
      {
        logout()
        return;
      }
    }, [])

    
    const logo_mobile   = isMobile?' logo_mobile':'';
    const is_auth_class = isAuth?' authenticated ':'';
    const onCollapse = (collapsed, type )=> {
        // console.log('dashboard::onCollpase:', collapsed, type);
        if(type=='clickTrigger')
          collapseMenu(collapsed)
        
      };
    const hidden_if_collapsed = menu_is_collapsed?' hidden':'';
    const mobile_class        = isMobile?'mobile_device':'';
    const content_style       = isMobile?
      (menu_is_collapsed?{}:{ margin: '24px 16px 0' }):{ margin: '24px 16px 0' };
    let _trigger = {};
    if(isMobile)
      _trigger = {trigger:null};
    return (
        <Layout style={{ minHeight: '100vh' }} className={`${mobile_class} ${is_auth_class}`}>
        <Sider 
          {..._trigger}
          collapsible 
          collapsed={menu_is_collapsed} 
          onCollapse={(collapsed, type) => {onCollapse(collapsed, type)}}
          defaultCollapsed={menu_is_collapsed} 
          theme="light"
          breakpoint="sm"
          onBreakpoint={broken => {
            // console.log( ' >>> Sider::broken? >>>' , broken);
          }}
          collapsedWidth={isMobile?0:80}
          >

            <div className={"logo" + logo_mobile}>
              <a href="/">
                <div className="img_container">
                  <img src={logoUrl} height="63" alt="Subdomain"/>
                </div>
              </a>
            </div>
            <div className={`logo_extra ${accountType} ${hidden_if_collapsed}`}>
              {
                !menu_is_collapsed &&
                <MenuAccountView account={accountProfile} className="menu_account_item" />
              }
            </div>
            { Menu ? <Menu area={item?.area} fileName={item?.fileName} itemPath={item?.itemPath} />: false }
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            { TopMenu? <TopMenu/>: <PixivusHeader/> }
          </Header>
          <Content style={content_style}>

            { Children? <Children/>: false }
            <BackTop />
          </Content>
          <Footer style={{ textAlign: 'center' }}><PixiFooter show_version={true}/></Footer>
        </Layout>
      </Layout>
    );
}
//
const DashboardContainer =
 connect(
    state => ({
      menuIsCollapsed:      menuRedux.isCollapsed(state),
      isMobile:             menuRedux.isMobile(state),
      accountName:          loginRedux.accountName(state),
      isAuth:               loginRedux.isAuth(state),
      accountType:          loginRedux.accountType(state),
      accountProfile:       loginRedux.accountProfile(state),
    }),
    dispatch => ({
      logout:               bindActionCreators(loginRedux.logout, dispatch),
      collapseMenu:         bindActionCreators(menuRedux.collapseMenu, dispatch)
    })
) (_DashboardContainer)

export default DashboardContainer;