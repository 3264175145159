import React from 'react';
import * as globalCfg from '@app/configs/global';
import { Tag } from 'antd';

const TagsViewer = (props) => {
  
  if(!props.item ||!props.item.tags)  
    return (null);
  return (
    <>{props.item.tags && props.item.tags.map((tag, idx)=>  <Tag className="tag_coloured" key={props.item._id+'_tag_'+tag} color={props.item.tags_colors[idx]}>{tag}</Tag>)}</>
    );
}
export default TagsViewer;
