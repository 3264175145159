import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import { BlankContainer } from '@app/containers/blank.container';
import DashboardContainer from '@app/containers/dashboard.container';

import MenuByRole from './menu';

import history from '@app/history.js';

import useMedia from 'react-media-hook2';
import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login';
import * as siteRedux from '@app/redux/models/site';

// Meta HACK!
// DO NOT REMOVE REDUX IMPORTS!!!!!!!!!!!!!
import * as account_redux from '@app/redux/models/accounts';
import * as site_redux from '@app/redux/models/site';
import * as core_redux from '@app/redux/models/core';
import * as bucketRedux from '@app/redux/models/buckets';
import * as transactionsRedux from '@app/redux/models/transactions';
import * as certificationsRedux from '@app/redux/models/certifications';
import * as usersRedux from '@app/redux/models/users';
import * as messagingRedux from '@app/redux/models/messaging';
// END HACK
import * as utils from '@app/utils/utils';
import * as globalCfg from '@app/configs/global';
import Buckets from '../pages/common/buckets';
import CreateBucket from '../pages/common/create_bucket';

const loadableComponent = (item) => {
  const asyncComponent = loadable(() => import(`../pages/${item.area}/${item.fileName}`), {
    fallback: <Spin style={{ marginTop: '100px' }} />,
  });

  let Container;
  if (item.container === 'dashboard') {
    Container = () => (
      <DashboardContainer
        footerText=""
        TopMenu=""
        Children={asyncComponent}
        Menu={MenuByRole}
        item={item}
      />
    );
  } else {
    Container = () => <BlankContainer Children={asyncComponent} />;
  }

  return () => <Container />;
};

const getPath = (item) => {
  if (item.param) return `/${item.area}/${item.path}/:${item.param}`;
  return `/${item.area}/${item.path}`;
};

const _DashboardRouter = ({ routes, isAuth, isAuthHack, accountType, setIsMobile, loginData }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isAuth);

  useEffect(() => {
    setIsAuthenticated(isAuth);
  }, [isAuth]);

  const [state_accountType, setAccountType] = useState(accountType);
  useEffect(() => {
    setAccountType(accountType);
  }, [accountType]);

  const isMobile = useMedia({
    id: 'DashboardContainer',
    query: '(max-width: 599px)',
  })[0];

  if (typeof setIsMobile === 'function') {
    setIsMobile(isMobile);
  }

  const myRoutes = () => {
    const my_isAuthenticated = isAuthenticated || isAuthHack;

    const __routes = routes
      .map((item) => {
        const fullPath = getPath(item);
        //fullPath.includes('post_details') && console.log('myRoutes:', state_accountType, isAuthenticated, item.required_access, fullPath);
        const hasAccess =
          my_isAuthenticated && ['*', state_accountType].includes(item.required_access);

        if (!hasAccess) return null;

        // fullPath.includes('post_details') && console.log('myRoutes:', state_accountType, isAuthenticated, item.required_access, fullPath);
        return <Route key={fullPath} path={fullPath} component={loadableComponent(item)} />;
      })
      .filter((route) => route != null);

    return __routes;
  };

  const isPixivusSubdomain = utils.isSubDomainPIXIVUS();
  const homeScreen = isPixivusSubdomain ? '/home' : '/login';

  return (
    <Router history={history}>
      <Switch>
        {isPixivusSubdomain && (
          <Route
            path="/home"
            component={loadableComponent({ area: 'general', fileName: 'home' })}
          />
        )}

        <Route
          path="/cancel_reset_password/:token"
          component={loadableComponent({ area: 'general', fileName: 'cancel_reset_password' })}
        />
        <Route
          path="/forgot_password"
          component={loadableComponent({ area: 'general', fileName: 'forgot_password' })}
        />
        <Route
          path="/reset_password/:token"
          component={loadableComponent({ area: 'general', fileName: 'reset_password_new' })}
        />
        <Route
          path="/confirm_email/:token"
          component={loadableComponent({ area: 'general', fileName: 'confirm_email' })}
        />
        <Route
          path="/login"
          component={loadableComponent({ area: 'general', fileName: 'login' })}
        />
        <Route
          path="/register"
          component={loadableComponent({ area: 'general', fileName: 'register' })}
        />
        <Route
          path="/post/:postId"
          component={loadableComponent({ area: 'general', fileName: 'post' })}
        />
        <Route
          path="/invitation/:token"
          component={loadableComponent({ area: 'general', fileName: 'invitation' })}
        />
        {myRoutes()}
        <Route
          exact
          path="/:bucketType/list"
          component={() => (
            <DashboardContainer
              footerText=""
              TopMenu=""
              Children={Buckets}
              Menu={MenuByRole}
              item={null}
            />
          )}
        />
        <Route
          exact
          path="/:bucketType/create"
          component={() => (
            <DashboardContainer
              footerText=""
              TopMenu=""
              Children={CreateBucket}
              Menu={MenuByRole}
              item={null}
            />
          )}
        />
        {!isAuth ? (
          <Route path="/" component={() => <Redirect to={homeScreen} />} />
        ) : (
          <Route path="/" component={loadableComponent({ area: 'general', fileName: 'home' })} />
        )}
      </Switch>
    </Router>
  );
};

export const DashboardRouter = connect(
  (state) => ({
    isAuth: loginRedux.isAuth(state),
    accountType: loginRedux.accountType(state),
  }),
  (dispatch) => ({
    setIsMobile: bindActionCreators(menuRedux.setIsMobile, dispatch),
  })
)(_DashboardRouter);
