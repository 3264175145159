import React, { useCallback, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import * as pixivus from '../../services/pixivus';

const { Option } = Select;

export class RemoteSelect extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetch = debounce(this.fetch, 800);
    this.fetch();

    this.state = {
      data: [],
      value: props.initialValues,
      fetching: false,
    };
  }
  componentDidMount() {
    this.fetch();
  }
  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.initialValues) !== JSON.stringify(prevProps.initialValues)) {
      this.setState({ value: this.props.initialValues });
    }
    if (prevProps.request !== this.props.request) {
      this.fetch();
    }
  }

  fetch = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });

    this.props.request(value).then((result) => {
      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return;
      }
      const data = this.props.resultParser(result);
      this.setState({ data, fetching: false });
    });
  };

  handleChange = (value) => {
    this.setState({
      value,
      data: this.state.data,
      fetching: false,
    });
    this.props.callback(value);
  };

  render() {
    const { fetching, data, value } = this.state;
    return (
      <Select
        showSearch
        allowClear
        mode={this.props.multiple ? 'multiple' : 'default'}
        labelInValue
        value={value}
        placeholder={this.props.placeholder || ''}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={this.fetch}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {data?.map(({ value, text }) => (
          <Option key={value}>{text}</Option>
        ))}
      </Select>
    );
  }
}

export const RemoteBucketSelect = ({
  placeholder,
  parameters = {},
  multiple,
  initialBuckets = [],
  callback,
}) => {
  const initialValues = initialBuckets?.map(({ name, _id }) => ({ key: _id, label: name }));
  const request = useCallback((value) => pixivus.buckets({ ...parameters, name: value }), []);

  return (
    <RemoteSelect
      callback={callback}
      placeholder={placeholder}
      initialValues={initialValues}
      request={request}
      resultParser={(result = []) =>
        result.map((bucket) => ({
          text: bucket.name,
          value: bucket._id,
        }))
      }
      multiple={multiple}
    />
  );
};
