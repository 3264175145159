import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.variable.min.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// function onColorChange(nextColor) {
//     const color = {
//       primaryColor: '#1890ff',
//       errorColor: '#ff4d4f',
//       warningColor: '#faad14',
//       successColor: '#52c41a',
//       infoColor: '#1890ff',
//     };
//     const mergedNextColor = { ...color, ...nextColor };
//     ConfigProvider.config({
//       theme: mergedNextColor,
//     });
//   }
