import * as globalCfg from '@app/configs/global';
import * as eosHelper from './eos-helper.js';
import * as jwtHelper from './jwt-helper';

export const isAuth = () => {
  const x = jwtHelper.getTokenIfNotExpired(jwtHelper.AUTH_TOKEN_KEY);
  // console.log('>>>>> isAuth:', x);
  return x!=null;
}

const valid_http_codes = [200, 201, 202, 203, 204]

/*
* Authenticate user against private bank server.
* 
* @param   {string}   account_name   EOS account name. 12 chars length.
* @param   {string}   private_key   EOS account wif (private key).
* @return  {string}   Bearer Token.
*/
export const auth = (account_name, private_key) =>   new Promise((res,rej)=> {
  
  const token = jwtHelper.getTokenIfNotExpired(jwtHelper.AUTH_TOKEN_KEY);
  
  if(!token)
  {
    const challenge_endpoint = globalCfg.pixivus.rest_endpoint+'/eos/challenge/'+account_name;
    
    fetch(challenge_endpoint, {method: 'GET'})
    .then((response) => {
        if (valid_http_codes.indexOf(parseInt(response.status))<0) {
          console.log(' CHALLENGE ********************************** ERROR#1', response.status, ' | response:', JSON.stringify(response))
          const _err = {'error':response.status}
          rej(_err);
          throw new Error(_err);
        }
        else
          return response.json()
      }, (err) => {
        console.log(' CHALLENGE  ********************************** !OK#2', err)
        rej(err.message);
        throw err;
      })
    .then((data) => {
      
      // console.log(' bank::auth >> ', JSON.stringify(data));
      const challenge = data.to_sign;

      eosHelper.signString(private_key, challenge).then((signed) => {  
        
        const auth_params = {
          'account_name': account_name
          , 'signature' : signed.data.signed_data
          , 'challenge':  challenge
        };
        
        const auth_endpoint      = globalCfg.pixivus.rest_endpoint+'/eos/auth';    
        // console.log('--------------------auth_params', auth_params)
        fetch(auth_endpoint, {
          method: 'POST',
          body: JSON.stringify(auth_params),
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          }
        })
          .then((response2) => {
            if (!response2.ok) {
              rej(response2.statusText);
              throw new Error(response2.statusText);
            }
            return response2.json()
          }, (err) => {
            rej(err); 
            throw err;
          })
          .then((auth_data) => {
            jwtHelper.setTokenToStorage(jwtHelper.AUTH_TOKEN_KEY, JSON.stringify(auth_data));
            res(jwtHelper.buildResponse(auth_data.token));

            return;
          }, (ex) => {
            rej(ex);
            return;
          });  


      } , (error) => {
        console.log('---- ERROR:', JSON.stringify(error));
        rej({error:error})
      });
    }, (ex) => {
      console.log(' CHALLENGE  ********************************** ERROR#5', JSON.stringify(ex))
      rej({error:ex});
    });
    
  }
  else{
    res(jwtHelper.buildResponse(token));
  }
})



