import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";

import * as loginRedux from '@app/redux/models/login'
import * as menuRedux from '@app/redux/models/menu';
import * as globalCfg from '@app/configs/global';
import { injectIntl, useIntl } from "react-intl";

const MenuAccountView = (props) => {
  const {formatMessage} = useIntl();
    
    const [account, setAccount]       = useState(null);
    useEffect(() => {
        setAccount(props.account);
    }, [props.account]);


    if(!account)
      return (null);
    
    const gotoAccountConfiguration = () => {
      props.setLastRootMenuFullpath(null);
      setTimeout(()=> {
        props.history.push({
          pathname: `/common/user/${props.accountId}`
          , state: { userId:props.accountId}
        })
      } ,250);
    }

    const getDisplayAccountData = (account_item) => {
      if(!account_item)
        return 'NA';
      const account_type       = formatMessage({id:`global.account_type.${props.accountType}`});
      const account_name       = props.accountName;
      
      
      return (<>
                <span className="menu_account_name">{account_name}</span>
                <div className="menu_account_container">
                  <span className="menu_account_type"><span className="uppercase">{formatMessage({id:'components.Views.account_menu.type'})}</span>: <span>{account_type}</span></span>
                </div>
              </>);
    }
    
    //
    const getIconForAccountType = (account_item) => {
      if(!account_item)
        return 'loading';
      return globalCfg.pixivus.faIconForAccountType(props.accountType);
      // guest       -> user
      // owner       -> user-shield / user-tie
      // superadmin  -> user-astronaut
      
    }

    // <Icon type={getIconForAccountType(account)} className={` ${props.accountType} menu_account_selected`}/>
    return (
      <div className={`${props.className||''}`} onClick={gotoAccountConfiguration}>
        <FontAwesomeIcon icon={getIconForAccountType(account)} className={` ${props.accountType} menu_account_item_icon menu_account_selected`}/>
        &nbsp;{getDisplayAccountData(account)}
      </div>
    );
    // `
}

export default (withRouter(connect(
    (state)=> ({
        accountName:             loginRedux.accountName(state),
        accountType:             loginRedux.accountType(state),
        accountId:               loginRedux.accountId(state)
    }),
    (dispatch)=>({
        setLastRootMenuFullpath: bindActionCreators(menuRedux.setLastRootMenuFullpath , dispatch),
    })
)( MenuAccountView) )
);
