/*
  Refer to https://firebase.google.com/docs/cloud-messaging/js/client?authuser=0
  and https://github.com/prescottprue/react-redux-firebase
  and https://www.npmjs.com/package/firebase
  and https://redux-saga-firebase.js.org/reference/dev/messaging
  and https://github.com/n6g7/redux-saga-firebase/tree/master/example/src/redux
  and https://firebase.google.com/docs/cloud-messaging/js/receive
*/

import { all, put, takeEvery } from 'redux-saga/effects';
import { store } from '../configureStore';
import * as core from './core';
import firebase from 'firebase';
import * as globalCfg from '@app/configs/global';
import rsf from '../rsf';

import * as pixivus from '@app/services/pixivus';
import logger from '@app/utils/logger';

const demo_messages = undefined;

export const SET_REGISTRATION_TOKEN = 'messaging/SET_REGISTRATION_TOKEN';
export const ON_MESSAGE = 'messaging/ON_MESSAGE';
export const ON_READ_MESSAGE = 'messaging/ON_READ_MESSAGE';
export const ON_READ_MESSAGES = 'messaging/ON_READ_MESSAGES';
export const REGISTER_IF_NOT = 'messaging/REGISTER_IF_NOT';
export const ON_SHOWN_MESSAGES = 'messaging/ON_SHOWN_MESSAGES';
export const ON_CLEAR_MESSAGES = 'messaging/ON_CLEAR_MESSAGES';

const LOAD_UNREAD_NOTIFICATIONS = 'messaging/LOAD_UNREAD_NOTIFICATIONS';
const SET_UNREAD_NOTIFICATIONS = 'messaging/SET_UNREAD_NOTIFICATIONS';
const END_LOAD_NOTIFICATIONS = 'messaging/END_LOAD_NOTIFICATIONS';

export const SHOW_MESSAGE = 'messaging/SHOW_MESSAGE';

export const setRegistrationToken = (token) => ({
  type: SET_REGISTRATION_TOKEN,
  payload: { token },
});
export const onReadMessage = (_id) => ({ type: ON_READ_MESSAGE, payload: { _id } });
export const clearMessages = (_id_array) => ({ type: ON_CLEAR_MESSAGES, payload: { _id_array } });
export const registerIfNot = () => ({ type: REGISTER_IF_NOT });
export const onReadMessages = () => ({ type: ON_READ_MESSAGES });
export const onShownMessages = () => ({ type: ON_SHOWN_MESSAGES });

export const showMessage = () => ({ type: SHOW_MESSAGE });

export const loadUnreadNotifications = (account_name) => ({
  type: LOAD_UNREAD_NOTIFICATIONS,
  payload: { account_name },
});

export const setUnreadNotifications = (notifications) => ({
  type: SET_UNREAD_NOTIFICATIONS,
  payload: { notifications },
});

function* requestPermissionSaga() {
  const messaging = firebase.messaging();

  try {
    yield messaging.requestPermission();
    messaging.usePublicVapidKey(globalCfg.firebase.vapid);
    const token = yield messaging.getToken();
    logger('PUSH-NOTIFICATION_TOKEN::getToken()', token);
    yield put(setRegistrationToken(token));
  } catch (error) {
    logger(' !!!! PUSH-NOTIFICATION_ERROR:', error);
  }
}

function* readMessagesSaga(obj) {
  logger('PUSH-NOTIFICATION::readMessagesSaga:#1', obj);
}

function* showMessageSaga(obj) {
  logger('PUSH-NOTIFICATION::showMessageSaga:#1', obj);
}

function* registerMessageHandlerSaga() {
  const messageChannel = rsf.messaging.channel();

  logger('PUSH-NOTIFICATION::try to register messages listener!');
  store.injectSaga('messaging', [
    ,
    // takeEvery(core.INIT, initMessaging)
    // takeEvery(REGISTER_IF_NOT, initMessaging)
    takeEvery(messageChannel, messageHandlerSaga),
    // , takeEvery(ON_READ_MESSAGES, readMessagesSaga)
  ]);
  logger('PUSH-NOTIFICATION::messages listener REGISTERED!');
}

/*
  Message structure:
  {
    message : {
      data:{
        account_name: "dargonarbizz"
        , amount: "8"
        , body: "type_payment@state_requested by atomakinnaka to dargonarbizz. Amount: 8.00. "
        , message: "type_payment@state_requested by atomakinnaka to dargonarbizz. Amount: 8.00. "
        , request_counter_id: "4041"
        , title: "New TRANSITION_NEW_REQUEST"
      }    
  }}
*/
function* messageHandlerSaga(message) {
  logger(' ============================ onMessage', JSON.stringify(message));
  yield put({
    type: ON_MESSAGE,
    payload: { message: { shown: false, read: false, message: message, mode: 'auto' } },
  });
}

function* registerMessageHandlerSaga2() {
  logger('PUSH-NOTIFICATION::registerMessageHandlerSaga2!');
  const messageChannel = rsf.messaging.channel();

  yield takeEvery(messageChannel, function* (message) {
    logger('PUSH-NOTIFICATION::registerMessageHandlerSaga2 >> NEW MESSAGE', message);
    // const notification = {...message.data, signature : JSON.parse(message.data.signature)};
    // // yield put({type: ON_MESSAGE, payload: {message : { shown:false, read:false, message:message, mode: 'auto'}}})
    // yield put({type: ON_MESSAGE, payload: {notification}})
    yield put({
      type: LOAD_UNREAD_NOTIFICATIONS,
      payload: { account_name: message.data.account_name },
    });
  });
}

function* loadUnReadNotificationsSaga({ type, payload }) {
  const { account_name } = payload;
  if (!account_name) return;
  logger('PUSH-NOTIFICATION::loadUnReadNotificationsSaga >> ME LLAMARON');
  try {
    // HACK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const data = yield pixivus.pendingSignatures(account_name);
    if (data) {
      yield put(setUnreadNotifications(data));
    }

    yield put({ type: END_LOAD_NOTIFICATIONS });
  } catch (e) {
    yield put({ type: END_LOAD_NOTIFICATIONS });
    // TODO -> throw global error!
  }
}

function* initMessaging() {
  logger('PUSH-NOTIFICATION::INIT-MESSAGING!!');
  const { registrationToken } = store.getState().messaging;
  if (registrationToken) return;
  yield requestPermissionSaga();
  logger('PUSH-NOTIFICATION::requested ok!');

  try {
    yield all([
      // registerMessageHandlerSaga(),
      registerMessageHandlerSaga2(),
      rsf.messaging.syncToken({
        successActionCreator: setRegistrationToken,
      }),
    ]);
  } catch (e) {
    console.log('PUSH-NOTIFICATION::ERROR:...', e);
  }

  // console.log('PUSH-NOTIFICATION::ABOUT TO syncMessages');
  // yield fork(
  //   rsf.messaging.syncMessages,
  //   { successActionCreator: showMessage }
  // );

  logger('PUSH-NOTIFICATION::yielded all ok!');
}

// //Se envan las sagas a redux estableciendo que y cuantas veces dispara la funcià¸£à¸“n
store.injectSaga('messaging', [
  takeEvery(core.INIT, initMessaging),
  takeEvery(REGISTER_IF_NOT, initMessaging),
  takeEvery(ON_READ_MESSAGES, readMessagesSaga),
  takeEvery(LOAD_UNREAD_NOTIFICATIONS, loadUnReadNotificationsSaga),
  takeEvery(SHOW_MESSAGE, showMessageSaga),
]);

// Selectores - Conocen el stado y retornan la info que es necesaria
export const token = (state) => state.messaging.registrationToken;
export const messages = (state) => state.messaging.messages;
export const loading_notifications = (state) => state.messaging.loading_notifications;

// El reducer del modelo
const defaultState = {
  registrationToken: null,
  messages: demo_messages || [],
  loading_notifications: false,
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_REGISTRATION_TOKEN:
      return { ...state, registrationToken: action.payload.token };
    case END_LOAD_NOTIFICATIONS:
      return { ...state, loading_notifications: false };
    case SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        messages: action.payload.notifications,
        loading_notifications: false,
      };
    case ON_MESSAGE:
      const messages = state.messages;
      return {
        ...state,
        messages: [action.payload.notification, ...messages],
      };
    case ON_SHOWN_MESSAGES:
      let shown_messages = state.messages.map((msg) => {
        msg.shown = true;
        return msg;
      });
      return {
        ...state,
        messages: shown_messages,
      };
    case ON_CLEAR_MESSAGES:
      let cleared_messages = state.messages.filter(
        (msg) => !action.payload._id_array.includes(msg.message._id)
      );
      return {
        ...state,
        messages: cleared_messages,
      };
    case ON_READ_MESSAGE:
      let read_messages1 = state.messages.map((msg) => {
        if (msg.message._id == action.payload._id) msg.read = true;
        return msg;
      });
      return {
        ...state,
        messages: read_messages1,
      };
    case ON_READ_MESSAGES:
      let read_messages = state.messages.map((msg) => {
        msg.read = true;
        return msg;
      });
      return {
        ...state,
        messages: read_messages,
      };
    default:
      return state;
  }
}

store.injectReducer('messaging', reducer);
