import antdEs from 'antd/lib/locale-provider/es_ES';
// import appLocaleData from "react-intl/locale-data/es";

const importMessages = async () => {
  let messages = await import('../locales/es_ES.json');
  return messages;
};
const EsLang = {
  getMessages: importMessages,
  messages: {},
  antd: antdEs,
  locale: 'es',
  // data: appLocaleData
  title: 'Castellano',
};
export default EsLang;
