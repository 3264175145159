import { getLocale } from '@app/lang/helper';
import React, { useEffect, useState } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// This component is in charge of reacting to i18n changes in the store and setting the corresponding message overrides
const LanguageReactor = ({ children, setMessages }) => {
  const intl = useIntl();
  const i18n = useSelector((state) => state?.site?.i18n);

  useEffect(() => {
    setMessages({ ...i18n?.[intl.locale] });
  }, [i18n, setMessages, intl.locale]);

  return children;
};

export function I18nProvider({ children }) {
  const [messageOverrides, setMessageOverrides] = useState({});
  const [currentAppLocale, setCurrentAppLocale] = useState({ locale: null, messages: null });

  useEffect(() => {
    const fetchLocale = async () => {
      const locale = await getLocale();
      setCurrentAppLocale(locale);
    };
    fetchLocale();
  }, [messageOverrides]);

  return currentAppLocale.messages ? (
    <IntlProvider
      onError={(err) => {}}
      locale={currentAppLocale.locale}
      defaultLocale="es"
      messages={{
        ...currentAppLocale.messages,
        ...messageOverrides,
      }}
    >
      <LanguageReactor setMessages={setMessageOverrides}>{children}</LanguageReactor>
    </IntlProvider>
  ) : null;
}
