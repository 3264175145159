import gql from 'graphql-tag';
import * as globalCfg from '@app/configs/global';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import * as jwtHelper from './jwt-helper';

import logger from '@app/utils/logger';

const do_log = false;
/*
 * Apollo configuration
 */
const httpLink = createHttpLink({
  uri: globalCfg.pixivus.graphql_endpoint,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const bearer_token = jwtHelper.getBearerTokenByKey(jwtHelper.AUTH_TOKEN_KEY);
  logger(bearer_token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: bearer_token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const USER_FIELDS = `
    _id
    account_name
    first_name
    last_name
    email
    account_type
    public_key
    max_buckets
    max_posts
    userCounterId
    numPosts
    numBuckets
    createdBuckets
    created_at
    email_confirmed
    email_notified
    email_notified_error
    domain
`;

const BUCKET_FIELDS = `
    _id
    created_by{
      _id
      account_name
    }
    bucketCounterId
    name
    description
    _type
    numPosts
    status
    created_at
    meta
    categoryOrder
    categories{
      _id
      key
      name
    }
`;

const SIGNATURE_FIELDS = `
    _id
    created_by{
      ${USER_FIELDS}
    }
    requested_to{
      ${USER_FIELDS}
    }
    post{
      _id
    }
    state
    signature_type
    tx_id
    block_timestamp
    signature_option
    sent_at
    sent_error
    updated_at
    created_at
`;

const INVITE_FIELDS = `
    _id
    created_by{
      ${USER_FIELDS}
    }
    requested_to{
      ${USER_FIELDS}
    }
    post{
      _id
    }
    state
    signature_type
    updated_at
    created_at
    sent_at
    sent_error
    created_at_desc
    email
`;

const POST_RAW_FIELDS = `
  _id               
  file_name         
  file_type         
  metadata          
  hash              
  raw_location      
  location          
  tx_id             
  drive_id          
  user_id           
  bucket_id           
  memo              
  title
  comment
  visible
  storage_type      
  created_at        
  block_timestamp
  captured_at
  tags
  tags_colors
  category_id
  category
  post_type
  numFiles
  is_tx_header
  origin
  geo{
    address
    dms
    map_uri
  }
  position{
    lat
    lng
  }
  numInvites
  numSignatures
  `;

const POSTS_FIELDS = `
  ${POST_RAW_FIELDS}
  user{
    ${USER_FIELDS}
  }
  bucket{
    ${BUCKET_FIELDS} 
  }
  postFiles{
    ${POST_RAW_FIELDS}
  }
  signatures{
    ${SIGNATURE_FIELDS}
  }
  invites{
    ${INVITE_FIELDS}
  }
`;

const array_to_string = (param) => {
  if (!param) return '';
  if (!Array.isArray(param)) return param;
  if (param.length == 0) return param[0];
  return param.join(',');
};

const GET_POSTS = gql`
  query GetPosts($page:String, $limit:String, $user_id:String, $tx_id:String, $hash:String, $storage_type:Int, $file_name:String, $location:String, $text_search:String, $bucket_id:String, $location_within:String, $account_id:String, $include_signatures_requests:Boolean, $account_name:String){
    posts(page:$page, limit:$limit, user_id:$user_id, tx_id:$tx_id, hash:$hash,storage_type:$storage_type, file_name:$file_name, location:$location, text_search:$text_search, bucket_id:$bucket_id, location_within:$location_within, account_id:$account_id, include_signatures_requests:$include_signatures_requests, account_name:$account_name){
      ${POSTS_FIELDS}
    }
  }
`;

export const posts = async ({
  page = '0',
  limit = '',
  _id = '',
  file_name = '',
  file_type = '',
  metadata = '',
  hash = '',
  raw_location = '',
  location = '',
  tx_id = '',
  drive_id = '',
  user_id = '',
  bucket_id = '',
  memo = '',
  storage_type = undefined,
  created_at = '',
  block_timestamp = '',
  text_search = '',
  location_within = '',
  account_id = '',
  account_name = '',
  include_signatures_requests = false,
} = {}) => {
  const _page = isNaN(page) ? page : page.toString();
  const _limit = isNaN(limit) ? limit : limit.toString();
  const a = {
    page: _page,
    limit: _limit,
    _id: _id,
    file_name: file_name,
    file_type: file_type,
    metadata: metadata,
    hash: hash,
    raw_location: raw_location,
    location: location,
    tx_id: tx_id,
    drive_id: drive_id,
    user_id: user_id,
    bucket_id: array_to_string(bucket_id),
    memo: JSON.stringify(memo),
    storage_type: storage_type,
    created_at: created_at,
    block_timestamp: block_timestamp,
    text_search: text_search,
    location_within: location_within,
    account_name: account_name,
    account_id: account_id,
    include_signatures_requests: include_signatures_requests,
  };
  //
  logger(' ######## GQLService::posts ', JSON.stringify(a));
  return runQuery(GET_POSTS, a, 'posts');
};

const GET_TRANSACTIONS = gql`
  query GetTransactions($page:String, $limit:String, $user_id:String, $tx_id:String, $hash:String, $storage_type:Int, $file_name:String, $location:String, $text_search:String, $bucket_id:String, $date_from:String, $date_to:String, $location_within:String, $account_id:String, $account_name:String){
    transactions(page:$page, limit:$limit, user_id:$user_id, tx_id:$tx_id, hash:$hash,storage_type:$storage_type, file_name:$file_name, location:$location, text_search:$text_search, bucket_id:$bucket_id,date_from:$date_from,date_to:$date_to location_within:$location_within, account_id:$account_id, account_name:$account_name){
      ${POSTS_FIELDS}
    }
  }
`;
export const transactions = async ({
  page = '0',
  limit = '',
  _id = '',
  file_name = '',
  file_type = '',
  metadata = '',
  hash = '',
  raw_location = '',
  location = '',
  tx_id = '',
  drive_id = '',
  user_id = '',
  bucket_id = '',
  date_from = '',
  date_to = '',
  memo = '',
  storage_type = undefined,
  created_at = '',
  block_timestamp = '',
  text_search = '',
  location_within = '',
  account_id = '',
  account_name = '',
} = {}) => {
  const _page = isNaN(page) ? page : page.toString();
  const _limit = isNaN(limit) ? limit : limit.toString();
  const a = {
    page: _page,
    limit: _limit,
    _id: _id,
    file_name: file_name,
    file_type: file_type,
    metadata: metadata,
    hash: hash,
    raw_location: raw_location,
    location: location,
    tx_id: tx_id,
    drive_id: drive_id,
    user_id: user_id,
    bucket_id: array_to_string(bucket_id),
    date_from,
    date_to,
    memo: JSON.stringify(memo),
    storage_type: storage_type,
    created_at: created_at,
    block_timestamp: block_timestamp,
    text_search: text_search,
    location_within: location_within,
    account_id: account_id,
    account_name: account_name,
  };
  //
  logger(' ######## GQLService::transactions ', JSON.stringify(a));
  return runQuery(GET_TRANSACTIONS, a, 'transactions');
};

const GET_POST = gql`
  query xxx($_id:String, $postCounterId:Int){
    post(_id:$_id, postCounterId:$postCounterId){
      ${POSTS_FIELDS}
    }
  }
`;
export const post = async ({ _id = '', postCounterId = null } = {}) => {
  const a = { _id: _id, postCounterId: postCounterId };
  logger(' ######## GQLService::post ', JSON.stringify(a));
  return runQuery(GET_POST, a, 'post');
};

const GET_STATS = gql`
  {
    stats {
      certificates_count
      buckets_count
      common_buckets_count
      biox_count
      pampeana_count
    }
  }
`;
export const stats = async () => {
  logger(' ######## GQLService::stats ');
  return runQuery(GET_STATS, null, 'stats');
};

const GET_PENDING_SIGNATURES = gql`
  query xxx($account_name:String){
    pending_signatures(account_name:$account_name){
      ${SIGNATURE_FIELDS}
    }
  }
`;
export const pending_signatures = async ({ account_name = '' } = {}) => {
  const a = { account_name: account_name };
  logger(' ######## GQLService::pending_signatures ', JSON.stringify(a));
  return runQuery(GET_PENDING_SIGNATURES, a, 'pending_signatures');
};

const GET_FULL_POST = gql`
  query xxx($_id:String, $postCounterId:Int, $tx_id:String){
    post_transaction(_id:$_id, postCounterId:$postCounterId, tx_id:$tx_id){
      ${POSTS_FIELDS}
    }
  }
`;
export const post_transaction = async ({ _id = '', postCounterId = null, tx_id = '' } = {}) => {
  const a = { _id: _id, postCounterId: postCounterId, tx_id: tx_id };
  logger(' ######## GQLService::post_transaction ', JSON.stringify(a));
  return runQuery(GET_FULL_POST, a, 'post_transaction');
};

const GET_BUCKET_TRANSACTIONS = gql`
  query xxx($bucket_id:String){
    bucket_transactions(bucket_id:$bucket_id){
      ${POSTS_FIELDS}
    }
  }
`;
export const bucket_transactions = async ({ bucket_id = '' } = {}) => {
  const a = { bucket_id: bucket_id };
  logger(' ######## GQLService::bucket_transactions ', JSON.stringify(a));
  return runQuery(GET_BUCKET_TRANSACTIONS, a, 'bucket_transactions');
};

const USERBUCKET_FIELDS = `
    _id
    permission
    bucket{
      ${BUCKET_FIELDS}
    }
    bucket_id
    user_id
`;

const BUCKETUSER_FIELDS = `
    _id
    permission
    permission_desc
    user{
      ${USER_FIELDS}
    }
    bucket_id
    user_id
`;

const GET_PROFILE = gql`
  query xxx($_id:String, $account_name:String, $userCounterId:Int, $public_key: String){
    profile(_id:$_id, account_name:$account_name, userCounterId:$userCounterId, public_key:$public_key){
      user{
        ${USER_FIELDS}
      }
      buckets{
        ${USERBUCKET_FIELDS}
      }
    }
  }
`;
export const profile = async ({
  _id = '',
  account_name = '',
  userCounterId = null,
  public_key = '',
} = {}) => {
  const a = {
    _id: _id,
    account_name: account_name,
    userCounterId: userCounterId,
    public_key: public_key,
  };
  logger(' ######## GQLService::profile ', JSON.stringify(a));
  return runQuery(GET_PROFILE, a, 'profile');
};

const GET_BUCKET_PROFILE = gql`
  query getBucketProfile($_id:String, $bucketCounterId:Int){
    bucket_profile(_id:$_id, bucketCounterId:$bucketCounterId){
      bucket
      {
        ${BUCKET_FIELDS}
        relationships{
          key
          buckets{
            _id
            name
            description
          }
        }
      }
      permissions{
        ${BUCKETUSER_FIELDS}
      }
    }
  }
`;
export const bucketProfile = async ({ _id = '', bucketCounterId = null } = {}) => {
  const a = { _id: _id, bucketCounterId: bucketCounterId };
  logger(' ######## GQLService::bucket_profile', JSON.stringify(a));
  return runQuery(GET_BUCKET_PROFILE, a, 'bucket_profile');
};

const GET_BUCKET_RELATIONSHIPS = gql`
  query getBucketRelationships($_id: String, $bucketCounterId: Int) {
    bucket_profile(_id: $_id, bucketCounterId: $bucketCounterId) {
      bucket {
        relationships {
          key
          buckets {
            _id
            name
            description
          }
        }
      }
    }
  }
`;
export const bucketRelationships = async ({ _id = '', bucketCounterId = null } = {}) => {
  const a = { _id: _id, bucketCounterId: bucketCounterId };
  logger(' ######## GQLService::bucket_relationships', JSON.stringify(a));
  return runQuery(GET_BUCKET_RELATIONSHIPS, a, 'bucket_profile');
};

const GET_USER = gql`
  query xxx($_id:String, $account_name:String, $userCounterId:Int, $public_key: String){
    user(_id:$_id, account_name:$account_name, userCounterId:$userCounterId, public_key:$public_key){
      ${USER_FIELDS}
    }
  }
`;

export const user = async ({
  _id = '',
  account_name = '',
  userCounterId = null,
  public_key = '',
} = {}) => {
  const a = {
    _id: _id,
    account_name: account_name,
    userCounterId: userCounterId,
    public_key: public_key,
  };
  logger(' ######## GQLService::user', JSON.stringify(a));
  return runQuery(GET_USER, a, 'user');
};

const GET_BUCKETS = gql`
  query getBuckets($page:String, $limit:String, $bucket_id:String, $name:String, $description:String, $text_search:String, $account_id:String, $_type: String){
    buckets(page:$page, limit:$limit, bucket_id:$bucket_id, name:$name, description:$description, text_search:$text_search, account_id:$account_id, _type:$_type){
      ${BUCKET_FIELDS}
    }
  }
`;
export const buckets = async ({
  page = '',
  limit = '',
  bucket_id = '',
  name = '',
  description = '',
  status = '',
  text_search = '',
  account_id = '',
  _type = '',
} = {}) => {
  const _page = isNaN(page) ? page : page.toString();
  const _limit = isNaN(limit) ? limit : limit.toString();
  const a = {
    page: _page,
    limit: _limit,
    bucket_id: bucket_id,
    name: name,
    description: description,
    status: status,
    text_search: text_search,
    account_id: account_id,
    _type: _type,
  };
  logger(' ######## GQLService::buckets', JSON.stringify(a));
  return runQuery(GET_BUCKETS, a, 'buckets');
};

const GET_USER_BUCKETS = gql`
  query xxx($page:String, $limit:String, $account_name:String, $user_id:String){
    user_buckets(page:$page, limit:$limit, account_name:$account_name, user_id:$user_id){
      _id
      permission
      bucket{
        ${BUCKET_FIELDS}
      }
    }
  }
`;
export const user_buckets = async ({
  page = '',
  limit = '',
  user_id = '',
  account_name = '',
} = {}) => {
  const a = { page: page, limit: limit, user_id: user_id, account_name: account_name };
  logger(' ######## GQLService::user_buckets', JSON.stringify(a));
  return runQuery(GET_USER_BUCKETS, a, 'user_buckets');
};

const GET_INVITES = gql`
  query xxx($page:String, $limit:String, $_id:String, $token:String){
    invites(page:$page, limit:$limit, _id:$_id, token:$token){
      ${INVITE_FIELDS}
    }
  }
`;
export const invite = async ({ page = '', limit = '', _id = '', token = '' } = {}) => {
  const a = { page: page, limit: limit, _id: _id, token: token };
  logger(' ######## GQLService::GET_INVITES', JSON.stringify(a));
  return runQuery(GET_INVITES, a, 'invites');
};

const GET_USERS = gql`
  query xxx($page:String, $limit:String, $account_name:String, $first_name:String, $last_name:String, $email:String, $account_type:String, $public_key:String, $max_buckets:Int, $userCounterId:Int, $created_at:String, $text_search:String){
    users(page:$page, limit:$limit, account_name:$account_name, first_name:$first_name, last_name:$last_name, email:$email, account_type:$account_type, public_key:$public_key, max_buckets:$max_buckets, userCounterId:$userCounterId, created_at:$created_at, text_search:$text_search){
      ${USER_FIELDS}      
    }
  }
`;

export const users = async ({
  page = '0',
  limit = '',
  account_name = '',
  first_name = '',
  last_name = '',
  email = '',
  account_type = '',
  public_key = '',
  max_buckets = null,
  userCounterId = null,
  created_at = '',
  text_search = '',
} = {}) => {
  const _page = isNaN(page) ? page : page.toString();
  const _limit = isNaN(limit) ? limit : limit.toString();
  const a = {
    page: _page,
    limit: _limit,
    account_name: account_name,
    first_name: first_name,
    last_name: last_name,
    email: email,
    account_type: account_type,
    public_key: public_key,
    max_buckets: max_buckets,
    userCounterId: userCounterId,
    created_at: created_at,
    text_search: text_search,
  };
  logger(' ######## GQLService::users', JSON.stringify(a));
  return runQuery(GET_USERS, a, 'users');
};

const GET_USERS_FOR_USER = gql`
  query xxx($page:String, $limit:String, $account_name:String, $text_search:String){
    users_for_account(page:$page, limit:$limit, account_name:$account_name, text_search:$text_search){
      ${USER_FIELDS}      
    }
  }
`;

export const users_for_user = async ({
  page = '0',
  limit = '',
  account_name = '',
  text_search = '',
} = {}) => {
  const _page = isNaN(page) ? page : page.toString();
  const _limit = isNaN(limit) ? limit : limit.toString();
  const a = { page: _page, limit: _limit, account_name: account_name, text_search: text_search };
  logger(' ######## GQLService::users_for_user', JSON.stringify(a));
  return runQuery(GET_USERS_FOR_USER, a, 'users_for_account');
};

const runQuery = async (query, variables, _return_field) => {
  // const client = useApolloClient();

  // logger('GRAPHQL::', query);
  try {
    // const res = await client.query({query: GET_CONFIG, forceFetch: true})
    const { loading, error, data } = await client.query({
      query: query,
      variables: variables,
      fetchPolicy: 'no-cache',
    });
    if (loading) return null;
    if (error) {
      logger('GRAPHQL::ERROR:', error);
      return null;
    }
    // logger(JSON.stringify(data));
    if (_return_field) return data[_return_field];
    return data;
  } catch (e) {
    logger(' ** graphql exception for => ', _return_field, e);
  }
  return null;
};
