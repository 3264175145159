import { takeEvery, put } from '@redux-saga/core/effects';
import { store } from '../configureStore';
import * as globalCfg from '@app/configs/global';
import * as storage from '@app/services/localStorage';
import * as core from './core';
import * as pixivus from '@app/services/pixivus';

import { TRY_DELETE_SESSION } from './page';
import history from '@app/history.js';

// Constantes
const TRY_LOGIN = 'login/TRY_LOGIN';
const TRY_LOGIN_END = 'login/TRY_LOGIN_END';
const TRY_LOGIN_ERROR = 'login/TRY_LOGIN_ERROR';
const SET_LOGIN = 'login/SET_LOGIN';
const LOGOUT = 'login/LOGOUT';
const CLEAR_SESSION = 'login/CLEAR_SESSION';

const LOAD_PROFILE = 'login/LOAD_PROFILE';
const SET_PROFILE = 'login/SET_PROFILE';

export const tryLogin = (account_name, password, remember) => ({
  type: TRY_LOGIN,
  payload: { account_name, password, remember },
});
export const tryLoginError = (e) => ({ type: TRY_LOGIN_ERROR, payload: { exception: e } });
export const logout = () => ({ type: LOGOUT });
export const clearSession = () => ({ type: CLEAR_SESSION });
export const setLoginData = (loginData) => ({ type: SET_LOGIN, payload: loginData });

export const loadProfile = (account_name) => ({ type: LOAD_PROFILE, payload: { account_name } });
export const setProfile = ({ account_name, profile }) => ({
  type: SET_PROFILE,
  payload: { account_name, profile },
});

//Eventos que requieren del async
function* initLoginDataSaga() {
  console.log(' # core.INIT@login-saga ');
  yield put({ type: core.ACTION_START, payload: { login: 'Check local storage' } });
  const { data } = yield storage.getAccountData();
  // console.log(' loginREDUX::loadLoginData >> storage >> ', JSON.stringify(data))

  if (data && data.account_name && data.password) {
    //yield put(tryLogin(data.account_name, data.password, false))
    const stateData = storage.getLoginDataFromStorage(data);
    yield put({ type: TRY_DELETE_SESSION });
    yield put(setLoginData(stateData));
  } else {
    console.log(' -- redux::login::loadLoginData >> could NOT LOGIN', JSON.stringify(data));
  }

  yield put({ type: core.ACTION_END, payload: 'login' });
}

function* tryLoginSaga({ type, payload }) {
  const { account_name, password, remember } = payload;
  try {
    const login_object = yield pixivus.login(account_name, password);
    if (payload.remember) {
      storage.setVersion(globalCfg.version);
      let master_account = account_name;
      const profile = login_object.profile;
      storage.setAccountData({ account_name, password, remember, profile });
    }
    yield put({ type: TRY_DELETE_SESSION });
    yield put(
      setLoginData({
        account_name: account_name,
        password: password,
        profile: login_object.profile,
      })
    );

    const messaging = store.getState().messaging;
    if (messaging && messaging.registrationToken) {
      const x = yield pixivus.subscribeToPushNotifications(
        account_name,
        messaging.registrationToken
      );
    }
  } catch (e) {
    console.log(' >> LOGIN REDUX ERROR#1', e);
    yield put({ type: TRY_LOGIN_ERROR, payload: { exception: e } });
  }
  yield put({ type: TRY_LOGIN_END });
  // yield put( tryUserState(account_name))
}

function* loadProfileSaga({ type, payload }) {
  const { account_name } = payload;

  if (!account_name) return;
  const profile = yield pixivus.getProfile(account_name);
  if (profile) {
    const { data } = yield storage.getAccountData();
    storage.setAccountData({
      account_name: account_name,
      password: data.password,
      remember: data.remember,
      profile: profile,
    });
    yield put(setProfile({ account_name: account_name, profile: profile }));
  }
}

function* logoutSaga() {
  setTimeout(() => {
    history.replace('/');
  }, 500);
  yield storage.clearStorage();
  storage.setVersion(globalCfg.version);
}

//Se envan las sagas a redux estableciendo que y cuantas veces dispara la funcià¸£à¸“n
store.injectSaga('login', [
  // takeEvery(core.INIT_READY_TO_START, initLoginDataSaga),
  takeEvery(core.INIT, initLoginDataSaga),
  takeEvery(TRY_LOGIN, tryLoginSaga),
  takeEvery(LOAD_PROFILE, loadProfileSaga),
  takeEvery(LOGOUT, logoutSaga),
  takeEvery(CLEAR_SESSION, logoutSaga),
]);

export const isLoading = (state) => state.login.loading > 0;
// export const isAuth                = (state) => state.login.account_name!=null;
export const isAuth = (state) => pixivus.auth.isAuth();
export const accountName = (state) => state.login.account_name;
export const accountProfile = (state) => (state.login.profile ? state.login.profile.user : null);
export const accountId = (state) => (state.login.profile ? state.login.profile.user._id : null);
export const accountBuckets = (state) => (state.login.profile ? state.login.profile.buckets : []);
export const accountType = (state) =>
  state.login.profile && state.login.profile.user ? state.login.profile.user.account_type : null;
export const privateKey = (state) => state.login.private_key;
export const initialPath = (state) =>
  state.login.profile &&
  state.login.profile.user &&
  state.login.profile.user.account_type == 'superadmin'
    ? '/common/dashboard'
    : '/common/transactions';
export const canCreateUsers = (state) =>
  state.login.profile && state.login.profile.user
    ? [globalCfg.pixivus.ACCOUNT_TYPE_OWNER, globalCfg.pixivus.ACCOUNT_TYPE_SUPERADMIN].includes(
        state.login.profile.user.account_type
      )
    : false;

export const isAdmin = (state) =>
  state.login.profile
    ? state.login.profile.user.account_type == globalCfg.pixivus.ACCOUNT_TYPE_SUPERADMIN
    : false;

export const isOwner = (state) =>
  state.login.profile
    ? state.login.profile.user.account_type == globalCfg.pixivus.ACCOUNT_TYPE_OWNER
    : false;

export const accountDomain = (state) =>
  state.login.profile ? state.login.profile.user.domain : null;

export const isHB4 = (state) =>
  state.login.profile
    ? (state.login.profile.user.domain || '').toLowerCase() == globalCfg.pixivus.HB4_DOMAIN
    : false;

export const isPampeana = (state) =>
  state.login.profile
    ? (state.login.profile.user.domain || '').toLowerCase() == globalCfg.pixivus.PAMPEANA_DOMAIN
    : false;

export const loginError = (state) => state.login.error;

const defaultState = {
  loading: 0,
  account_name: null,
  private_key: undefined,
  error: null,
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case TRY_LOGIN:
      return {
        ...state,
        loading: state.loading + 1,
        error: null,
      };
    case TRY_LOGIN_END:
      return {
        ...state,
        loading: state.loading - 1,
      };
    case TRY_LOGIN_ERROR:
      return {
        ...state,
        loading: 0,
        error: action.payload.exception,
      };
    case SET_LOGIN:
      return {
        ...state,
        private_key: action.payload.password,
        account_name: action.payload.account_name,
        profile: action.payload.profile,
        error: null,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
      };
    case LOGOUT:
    case CLEAR_SESSION:
      return defaultState;
    default:
      return state;
  }
}

store.injectReducer('login', reducer);
