import React, { useEffect, useState } from 'react';
import * as globalCfg from '@app/configs/global';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import { Policy } from './Policy';

const PixiFooter = (props) => {
  const [intl, setIntl] = useState({});

  useEffect(() => {
    const { formatMessage } = props.intl;
    let _intl = {};

    _intl.registry_explorer = formatMessage({ id: 'app.footer.registry_explorer' });
    _intl.app_footer = formatMessage({ id: 'app.footer' });
    _intl.powered_by = formatMessage(
      { id: 'app.footer.powered_by' },
      {
        a: () => (
          <Button className="powered_by" type="link" target="_blank" href="https://www.pixivus.com">
            PIXIVUS
          </Button>
        ),
      }
    );

    setIntl(_intl);
  }, [props.intl]);

  return (
    <>
      {intl?.app_footer + ` © ${new Date().getFullYear()} Version ${globalCfg.version}`}
      {intl?.powered_by && (
        <>
          <br />
          {intl.powered_by}
        </>
      )}
      {props.children && (
        <>
          <br />
          {props.children}
          <br />
        </>
      )}
      <Policy termsAndCondId={56784283} privacyPolicyId={26010795} cookiePolicyId={26010795} />
      <Button type="link" target="_blank" href="https://app.pixivus.com/home">
        {intl.registry_explorer}
      </Button>
    </>
  );
};

export default injectIntl(PixiFooter);
