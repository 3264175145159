import { takeEvery, put } from '@redux-saga/core/effects';
import { store } from '../configureStore';

import * as pixivus from '@app/services/pixivus';

// Constantes
const LOAD_TRANSACTIONS = 'transactions/LOAD_TRANSACTIONS';
const END_LOAD_TRANSACTIONS = 'transactions/END_LOAD_TRANSACTIONS';
const START_LOAD_TRANSACTIONS = 'transactions/START_LOAD_TRANSACTIONS';
const SET_TRANSACTIONS = 'transactions/SET_TRANSACTIONS';
const LOAD_MORE_TRANSACTIONS = 'transactions/LOAD_MORE_TRANSACTIONS';
const SET_MORE_TRANSACTIONS = 'transactions/SET_MORE_TRANSACTIONS';

const LOAD_BUCKET_TRANSACTIONS = 'transactions/LOAD_BUCKET_TRANSACTIONS';

const ON_POPUP_TRANSACTION = 'transactions/ON_POPUP_TRANSACTION';

// Creadores de acciones (se pueden usar desde los compoenentes)
export const load = (filter, key) => ({ type: LOAD_TRANSACTIONS, payload: { filter, key } });
export const set = (transactions, filter, key) => ({
  type: SET_TRANSACTIONS,
  payload: { transactions, filter, key },
});

export const loadMore = (filter, key) => ({
  type: LOAD_MORE_TRANSACTIONS,
  payload: { filter, key },
});
export const setMore = (transactions, filter, key) => ({
  type: SET_MORE_TRANSACTIONS,
  payload: { transactions, filter, key },
});

// const loadBucketTxs
export const loadBucketTxs = (filter, key) => ({
  type: LOAD_BUCKET_TRANSACTIONS,
  payload: { filter, key },
});

export const setPopUp = (obj) => ({ type: ON_POPUP_TRANSACTION, payload: { post: obj } });

function* loadBucketTransactionsSaga({ type, payload }) {
  const { filter, key } = payload;
  if (!filter || !key) {
    console.log('REDUX::TRANSACTIONS::loadBucketTransactionsSaga() -- DEAD#1');
    return;
  }
  yield put({ type: START_LOAD_TRANSACTIONS });
  try {
    console.log('REDUX::TRANSACTIONS::loadBucketTransactionsSaga() -- requesting', filter);
    const data = yield pixivus.bucketTransactions(filter);
    if (data) {
      console.log('REDUX::TRANSACTIONS::loadBucketTransactionsSaga():', data);
      yield put(set(data, filter, key));
    }

    yield put({ type: END_LOAD_TRANSACTIONS });
  } catch (e) {
    yield put({ type: END_LOAD_TRANSACTIONS });
    // TODO -> throw global error!
  }
}
//Eventos que requieren del async
function* loadTransactionsSaga({ type, payload }) {
  const { filter, key } = payload;
  if (!filter || !key) {
    console.log('REDUX::TRANSACTIONS::loadTransactionsSaga() -- DEAD#1');
    return;
  }
  yield put({ type: START_LOAD_TRANSACTIONS });
  try {
    console.log('REDUX::TRANSACTIONS::loadTransactionsSaga() -- requesting', filter);
    const data = yield pixivus.transactions(filter);
    // const data = yield pixivus.posts(filter);
    if (data) {
      console.log('REDUX::TRANSACTIONS::loadTransactionsSaga():', data);
      yield put(set(data, filter, key));
    }

    yield put({ type: END_LOAD_TRANSACTIONS });
  } catch (e) {
    yield put({ type: END_LOAD_TRANSACTIONS });
    // TODO -> throw global error!
  }
}

function* loadMoreTransactionsSaga({ type, payload }) {
  const { filter, key } = payload;
  if (!filter || !key) return;
  yield put({ type: START_LOAD_TRANSACTIONS });
  try {
    // const data = yield pixivus.transactions(filter);
    const data = yield pixivus.posts(filter);
    if (data) {
      yield put(setMore(data, filter, key));
    }

    yield put({ type: END_LOAD_TRANSACTIONS });
  } catch (e) {
    yield put({ type: END_LOAD_TRANSACTIONS });
    // TODO -> throw global error!
  }
}

function* initLoadTransactions() {
  // console.log( ' # core.INIT@transactions-saga ' )
  // yield put({type: core.ACTION_START, payload: { loadTransactions: 'Loading transactions'}})
  // yield call(loadTransactionsSaga)
  // yield put({type: core.ACTION_END, payload: 'loadTransactions'})
}

store.injectSaga('transactions', [
  // takeEvery(core.INIT, initLoadTransactions),
  takeEvery(LOAD_TRANSACTIONS, loadTransactionsSaga),
  takeEvery(LOAD_BUCKET_TRANSACTIONS, loadBucketTransactionsSaga),
  takeEvery(LOAD_MORE_TRANSACTIONS, loadMoreTransactionsSaga),
]);

// Selectores - Conocen el stado y retornan la info que es necesaria
export const transactions = (state) => state.transactions.transactions;
export const isLoading = (state) => state.transactions.is_loading;
export const popPostId = (state) => state.transactions.popup_post;

// El reducer del modelo
const defaultState = {
  transactions: {},
  filters: [],
  is_loading: false,
  popup_post: null,
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case LOAD_MORE_TRANSACTIONS:
      return { ...state, is_loading: true };
    case LOAD_TRANSACTIONS:
      return { ...state, is_loading: true };
    case START_LOAD_TRANSACTIONS:
      return { ...state, is_loading: true };
    case END_LOAD_TRANSACTIONS:
      return { ...state, is_loading: false };

    case ON_POPUP_TRANSACTION:
      return { ...state, popup_post: action.payload.post, is_loading: false };

    case SET_TRANSACTIONS:
      let new_transactions = state.transactions;
      new_transactions[action.payload.key] = action.payload.transactions;
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.key]: [...action.payload.transactions],
        },
        is_loading: false,
      };
    case SET_MORE_TRANSACTIONS:
      let new_transactions2 = state.transactions;
      new_transactions2[action.payload.key] = [
        ...(new_transactions2[action.payload.key] || []),
        ...action.payload.transactions,
      ];
      return { ...state, transactions: new_transactions2, is_loading: false };
    default:
      return state;
  }
}

store.injectReducer('transactions', reducer);
