export const mimeToIcon = (mimetype) =>{
  return 'file';  
      // file-excel
      // file-image
      // file-markdown
      // file-pdf
      // file-ppt
      // file-text
      // file-word
      // file-zip
      // file-unknown
      // file
      // file-jpg
      
    
}

