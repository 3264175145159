import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login';
import * as siteRedux from '@app/redux/models/site';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as routes_config from '@app/configs/routes';

import { getRootKeys } from '@app/services/routes';
import InjectMessage from '@app/components/intl-messages';

import { FormattedMessage } from 'react-intl';

export const MenuByRole = ({
  area,
  fileName,
  itemPath,
  items = [],
  getMenu,
  accountName,
  accountType,
  lastRootMenu,
  menuIsCollapsed,
  collapseMenu,
  isMobile,
  bucketTypes,
}) => {
  const [myAccountName, setAccountName] = useState(null);
  const [myAcountType, setAcountType] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (myAcountType != accountType && myAccountName != accountName) {
      setAccountName(accountName);
      setAcountType(accountType);
      getMenu(accountName, accountType);
    }
  }, [myAcountType, accountType, myAccountName, accountName, getMenu]);

  const bucketRoutes = useMemo(() => {
    return bucketTypes?.reduce((acc, { key, name }) => {
      acc.push(
        {
          key,
          title: name,
          resolvedPath: `/${key}/list`,
          path: '',
          icon: 'container',
        },
        {
          key: `create-${key}`,
          title: `crear ${name}`,
          resolvedPath: `/${key}/create`,
          path: '',
          icon: 'plus-square',
        }
      );
      return acc;
    }, []);
  }, [bucketTypes]);

  const bb = menuIsCollapsed ? [] : getRootKeys(accountType);

  const getIcon = ({ icon }) => {
    return <LegacyIcon type={icon || 'minus'} />;
  };

  const getItem = ({ key, icon, resolvedPath, path, title, items, bottom_divider }) => {
    return [
      {
        key,
        icon: getIcon({ icon }),
        children: items ? items.flatMap(getItem) : undefined,
        label: (
          <Link to={resolvedPath || routes_config.getPath(path || key)}>
            <FormattedMessage id={title} />
          </Link>
        ),
        path: resolvedPath || routes_config.getPath(path || key),
        type: undefined,
      },
      bottom_divider ? { type: 'divider' } : undefined,
    ];
  };

  const onMenuSelect = (e) => {
    if (isMobile) collapseMenu(true);
  };

  const formattedItems = [
    ...items.flatMap(getItem),
    { type: 'divider' },
    ...bucketRoutes.flatMap(getItem),
  ];
  const defaultKeySelected = formattedItems.find(
    ({ path } = {}) => path === location.pathname
  )?.key;

  return (
    <Menu
      //TODO: This key prop is used to renew the defaultSelectedKeys prop, it's a workaround but
      // other solutions need a big refactor (Redux slice and etc..)
      key={defaultKeySelected}
      onSelect={onMenuSelect}
      defaultSelectedKeys={[defaultKeySelected]}
      defaultOpenKeys={bb}
      mode="inline"
      theme="light"
      items={formattedItems}
    />
  );
};

export default connect(
  (state) => ({
    items: menuRedux.getMenuItems(state),
    accountName: loginRedux.accountName(state),
    accountType: loginRedux.accountType(state),
    isMobile: menuRedux.isMobile(state),
    lastRootMenu: menuRedux.lastRootMenu(state),
    menuIsCollapsed: menuRedux.isCollapsed(state),
    bucketTypes: siteRedux.bucketTypes(state),
  }),
  (dispatch) => ({
    getMenu: bindActionCreators(menuRedux.getMenu, dispatch),
    collapseMenu: bindActionCreators(menuRedux.collapseMenu, dispatch),
  })
)(MenuByRole);
