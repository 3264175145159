import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon as LegacyIcon } from '@ant-design/compatible';

const Skeleton = ({content, icon, title='', simple_mode, is_ant_icon, keepMaxWidth = true}) => {
    
    const the_icon = is_ant_icon
      ?<LegacyIcon type={icon}/>
      :<FontAwesomeIcon icon={icon||'user'} size="4x" color="black"/>;

    if(simple_mode==true)
    {
      return(
        <div className="cards" style={{maxWidth: keepMaxWidth? undefined : 'unset'}}>
          <section className="my-data my-data-firts " style={{backgroundColor:'#fcfcfc'}}>
            <div className="info-personal">
              <div className="form_header">
                <div className="form_header_icon">
                      {the_icon}
                </div>
                {title && <div className="form_header_text">{title}</div>}
              </div>
              
              {content}

            </div>
          </section>
        </div>      
      );
    }

    return(

      <div className="cards">
        <section className="my-data my-data-firts info-account " style={{backgroundColor:'#fcfcfc'}}>
          <div className="info-personal">
            <div className="my-data-info add-picture" >
              <div className="add-picture-content" style={{width: 125, height: 125, position: 'relative'}}>
                <div id="pickfiles" className="add-picture-badge-img" style={{width: 125, height: 125, zIndex: 10, position: 'relative'}}>
                  <span id="pickfileDefault" className="add-picture-badge" style={{border: '1px solid rgb(153, 153, 153)', backgroundColor: 'white', display:'flex', justifyContent:'center', alignItems:'center'}}
                    >
                    {the_icon}
                  </span>                  
                </div>
              </div>
              {title && <h3>{title}</h3> }
            </div>

            {content}

          </div>
        </section>
      </div>      
    )

    
}
//
export default (Skeleton)

