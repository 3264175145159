import React, { useState, useEffect, useRef } from 'react';

import { Tooltip, Tag, Input, Button } from 'antd';

import { connect } from 'react-redux'

import * as loginRedux from '@app/redux/models/login'

import * as globalCfg from '@app/configs/global';
import * as utils from '@app/utils/utils';

import * as pixivus from "@app/services/pixivus";

import { injectIntl } from "react-intl";

import _ from 'lodash';

import AutocompleteAccount from '@app/components/AutocompleteAccount';

const AccountSelector = (props) => {

  const [tags, setTags]       = useState(props.tags);
  const [intl, setIntl]       = useState({});

  const {formatMessage} = props.intl;
  
  const arraysDeepEqual = (a, b) => {
    if (a === b) return true;
    if (a == null && b == null) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;
    const a_sorted = a.sort();
    const b_sorted = b.sort();
    for (var i = 0; i < a_sorted.length; ++i) {
      if (a_sorted[i] !== b_sorted[i]) return false;
    }
    return true;
  }

  useEffect(() => {
      // console.log('------------useEffect:', props.tags)
      if(props.tags.length>2)
      {  
        console.log('--props.tags--GT>2!')
        return;
      }
      if(!arraysDeepEqual(tags, props.tags))
      {
        console.log('--props.tags--FIRECHANGE!')
        setTags(props.tags);
      }
      else
      {
        console.log('--props.tags--NOT FIRECHANGE!')
      }
    }, [props.tags]);

  let autocompleteRef = null;

  const handleClose = removedTag => {
    const _tags = tags.filter(tag => tag !== removedTag);
    setTags(_tags);
  };

  useEffect(() => {
      // if(tags.length>2)
      // {  
      //   console.log('--tags--GT>2!')
      //   return;
      // }
      if(!arraysDeepEqual(tags, props.tags))
      {  
        console.log('--tags--FIRECHANGE!')
        fireChange();
      }
      else{
        console.log('--tags--NOT FIRECHANGE!')
      }
    }, [tags]);
  
  const fireChange = () => {
    if(typeof props.callback == 'function')
      props.callback(tags);
  };

  const onAccountSelected = (value) => {
    // console.log('------------onAccountSelected:', value)
    if(!tags.includes(value))
    {
      // console.log('------------setting tags', [value, ...tags])
      setTags([value, ...tags]);
    }

    if(autocompleteRef )
    {
      // console.log('----reseting autocomplete!');
      setTimeout(()=> {
        autocompleteRef.reset()
      } ,100);
      
    }
    else{
       // console.log('----NO SE PUEDE RESET autocomplete!', autocompleteRef)
    }
    return;
  }

  // console.log(' ******************************* rendering... ', tags);
  // onRef={(ref) => autocompleteRef=ref}
  let the_props = {...props}
  delete the_props.callback;
  
  return(
    <div>

      <AutocompleteAccount {...the_props} callback={onAccountSelected}/>
      
      <div className="tagsContainer gray_bg">
          {tags.map((tag, index) => {
            const isLongTag = tag.length > 20;
            const tagElem = (
              <Tag key={tag} closable={true} onClose={() => handleClose(tag)}>
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
      </div> 
    </div>
  );

}
export default 
   (connect((state)=> ({
      isAdmin:               loginRedux.isAdmin(state)

    }),
    (dispatch)=>({
      
    })
)( injectIntl(AccountSelector) ) );
