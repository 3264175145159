import logger from '@app/utils/logger';

export const AUTH_TOKEN_KEY = 'pixivus_auth_token_key';

const isTimestampExpired = (expires_at) => {
  if (!expires_at) return true;

  const _now = Math.floor(new Date().getTime() / 1000);
  const _substraction = expires_at - _now;
  // console.log(
  //   '>>>>> JWT Expiration: ',
  //   'expires_at:',
  //   expires_at,
  //   '_now:',
  //   _now,
  //   ' resta:',
  //   _substraction
  // );
  return _substraction < 0;
};

// function isValidUnixDate(unixDate){
//   return (Math.floor((new Date()).getTime() / 1000)|0)<unixDate;
// }

export const buildResponse = (_token) => {
  return { token: _token, bearer_token: getBearerToken(_token) };
};

export const getBearerTokenByKey = (key) => {
  if (!key) key = AUTH_TOKEN_KEY;
  return getBearerToken(getTokenIfNotExpired(key));
};

export const getBearerToken = (token) => {
  if (!token) return null;
  return 'Bearer ' + token;
};

export const getTokenFromStorage = (key) => {
  return localStorage.getItem(key);
};

export const setTokenToStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getTokenIfNotExpired = (key) => {
  let _token = getTokenFromStorage(key);
  if (!_token) return null;
  let json_token = JSON.parse(_token);
  //logger('jwtHelper::getTokenIfNotExpired >> ', json_token);

  return isTimestampExpired(json_token.expires_at) ? null : json_token.token;
};

export const apiCall = (path, method, data, timeout) =>
  new Promise((res, rej) => {
    let bearer_token;
    let _key = AUTH_TOKEN_KEY;

    bearer_token = getBearerToken(getTokenIfNotExpired(_key));
    method = method || 'GET';

    logger(_key, bearer_token);
    let fetchOptions = {
      method: method,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: bearer_token,
      },
    };

    // POST PARAMS
    if (typeof data !== 'undefined' && data && method != 'GET') {
      fetchOptions.body = JSON.stringify(data);
    }

    if (typeof data !== 'undefined' && data && method == 'GET') {
      path =
        path +
        '?' +
        Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join('&');
    }

    logger(' ###### jwtHelper::apiCall >> path:', path);
    logger(' ###### jwtHelper::apiCall >> method', method || 'GET');
    logger(' ###### jwtHelper::apiCall >> fetchOptions.body', JSON.stringify(fetchOptions.body));
    logger(' ###### jwtHelper::apiCall >> fetchOptions.headers', bearer_token);

    let timer = null;
    if (timeout && !isNaN(timeout) && timeout > 1000)
      timer = setTimeout(() => rej(new Error('Request timed out')), timeout);

    fetch(path, fetchOptions)
      .then(
        (resp) => resp.json(),
        (ex) => {
          logger('ApiCallError#0');
          logger(ex);
          rej(ex);
        }
      )
      .finally(() => timer != null && clearTimeout(timer))
      .then(
        (data) => {
          logger(' ###### jwtHelper::apiCall >> result:', JSON.stringify(data));

          if (!data) {
            logger('ApiCallError#0.5');
            logger(data);
            rej('UNKNOWN ERROR!');
            return;
          }

          if (data && data.error) {
            logger('ApiCallError#1');
            logger(data.error);
            rej(data.error);
            return;
          }

          if (data && data.errors) {
            logger('ApiCallError#2');
            logger(data.errors);
            rej(data.errors[0]);
            return;
          }

          res(data);
        },
        (err) => {
          logger('ApiCallError#3');
          logger(err);
          rej({ error: err, networkError: true });
        }
      );
  });
