import { takeEvery, put } from '@redux-saga/core/effects';
import { store } from '../configureStore';
import * as pixivus from '@app/services/pixivus';
import * as core from './core';

// Constants
const START_LOAD_CONFIG = 'site/START_LOAD_CONFIG';
const SET_CONFIG = 'site/SET_CONFIG';
const END_LOAD_CONFIG = 'site/END_LOAD_CONFIG';

// Actions Creators
export const loadConfig = () => ({ type: START_LOAD_CONFIG });

// Sagas
function* loadSiteConfig() {
  const data = yield pixivus.getSiteConfig();
  if (data) {
    yield put({ type: SET_CONFIG, payload: data });
  } else {
    yield put({ type: END_LOAD_CONFIG });
  }
}
function* initLoadSite() {
  yield put({ type: START_LOAD_CONFIG });
}

store.injectSaga('site', [
  takeEvery(START_LOAD_CONFIG, loadSiteConfig),
  takeEvery(core.INIT, initLoadSite),
]);

// Selectors
export const site = (state) => state.site;
export const name = (state) => state.site.name;
export const i18n = (state) => state.site.i18n;
export const subdomain = (state) => state.site.subdomain;
export const bucketTypes = (state) => state.site.bucketTypes;
export const bucketTypeByKey = (state, key) => state.site.bucketTypes?.find((x) => x.key === key);

// Initial State
const initialState = {
  error: false,
  isLoading: false,
  key: '',
  name: '',
  i18n: {},
  subdomain: '',
  config: {},
  bucketTypes: [],
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case START_LOAD_CONFIG:
      return { ...state, isLoading: true };

    case SET_CONFIG:
      return { ...state, isLoading: false, error: false, ...action.payload };

    case END_LOAD_CONFIG:
      return { ...state, error: true, isLoading: false };

    default:
      return { ...state };
  }
}

store.injectReducer('site', reducer);
