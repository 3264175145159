import antdBr from 'antd/lib/locale-provider/pt_BR';
// import appLocaleData from "react-intl/locale-data/pt";

const importMessages = async () => {
  let messages = await import('../locales/pt_BR.json');
  return messages;
};

const BrLang = {
  getMessages: importMessages,
  messages: {},
  antd: antdBr,
  locale: 'pt-BR',
  // data: appLocaleData
  title: 'Português',
};
export default BrLang;
