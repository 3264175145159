import { takeEvery, put, call } from '@redux-saga/core/effects';
import { store } from '../configureStore'
import * as api from '@app/services/pixivus'
import * as core from './core';

// Constantes
const LOAD_USERS       = 'users/LOAD_USERS'
const LOAD_MORE_USERS  = 'users/LOAD_MORE_USERS'
const END_LOAD_USERS   = 'users/END_LOAD_USERS'
const SET_USERS        = 'users/SET_USERS'
const SET_MORE_USERS   = 'users/SET_MORE_USERS';

// Creadores de acciones (se pueden usar desde los compoenentes)
export const load      = (filter, key)        => ({ type: LOAD_USERS, payload: {filter, key} });
export const loadMore  = (filter, key)        => ({ type: LOAD_MORE_USERS, payload: {filter, key} });
export const set       = (users, filter, key) => ({ type: SET_USERS, payload: {users, filter, key}});
export const setMore   = (users, filter, key) => ({ type: SET_MORE_USERS, payload: {users, filter, key}});

//Eventos que requieren del async
function* loadUsersSaga({type, payload}) {
  const {filter, key} = payload;
  if(!filter || !key)
    return;
  
  try
  {
    const data = yield api.users(filter);
    if(data) {
      yield put(set(data, filter, key))
    }

    yield put({ type: END_LOAD_USERS })
  }
  catch(e){
    yield put({ type: END_LOAD_USERS })
    // TODO -> throw global error!
  }
}

function* loadMoreUsersSaga({type, payload}) {
  const {filter, key} = payload;
  if(!filter || !key)
    return;
  
  try
  {
    const data = yield api.users(filter);
    if(data) {
      yield put(setMore(data, filter, key))
    }

    yield put({ type: END_LOAD_USERS })
  }
  catch(e){
    yield put({ type: END_LOAD_USERS })
    // TODO -> throw global error!
  }
}

function* initLoadUsers () {
  
}

store.injectSaga('users', [
  takeEvery(LOAD_USERS, loadUsersSaga),
  takeEvery(LOAD_MORE_USERS, loadMoreUsersSaga),
]);

// Selectores - Conocen el stado y retornan la info que es necesaria
export const users        = (state) => state.users.users ;
export const isLoading    = (state) => state.users.is_loading


// El reducer del modelo
const defaultState = {
    users:            [],
    filters:          [],
    is_loading:       false
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case LOAD_MORE_USERS:
      return { ...state
              , is_loading: true}
    case LOAD_USERS: 
      return { ...state
              , is_loading: true}
    case END_LOAD_USERS: 
      return { ...state
              , is_loading: false}
    case SET_USERS: 
      let new_users = state.users;
      new_users[action.payload.key] = action.payload.users;
      // console.log('REDUX::', action.payload.key, ' = ',action.payload.users);
      return  { ...state
                , users: new_users
                , is_loading: false}
    case SET_MORE_USERS:
      let new_users2 = state.users;
      new_users2[action.payload.key] = [...(new_users2[action.payload.key]||[]), ...action.payload.users];
      return  { ...state
                , users: new_users2
                , is_loading: false}
    default: return state;
  }
}

store.injectReducer('users', reducer)