import { takeEvery, put, call } from '@redux-saga/core/effects';
import { store } from '../configureStore'
import * as pixivus from '@app/services/pixivus'
import * as core from './core';

// Constantes
const LOAD_CERTIFICATIONS      = 'certifications/LOAD_CERTIFICATIONS'
const END_LOAD_CERTIFICATIONS  = 'certifications/END_LOAD_CERTIFICATIONS'
const SET_CERTIFICATIONS       = 'certifications/SET_CERTIFICATIONS'

const LOAD_STATS               = 'certifications/LOAD_STATS'
const SET_STATS                = 'certifications/SET_STATS'

// Creadores de acciones (se pueden usar desde los compoenentes)
export const load      = (filter, key)                 =>({ type: LOAD_CERTIFICATIONS, payload: {filter, key} });
export const set       = (certifications, filter, key) =>({ type: SET_CERTIFICATIONS, payload: {certifications, filter, key}});
export const setStats  = (data)                        =>({ type: SET_STATS, payload: {data:data}});
export const loadStats = ()                            =>({ type: LOAD_STATS });

//Eventos que requieren del async
function* loadCertificationsSaga({type, payload}) {
  const {filter, key} = payload;
  if(!filter || !key)
  {
    return;
  } 

  try
  {
    const data = yield pixivus.posts(filter);
    if(data) {
      yield put(set(data, filter, key))
    }

    yield put({ type: END_LOAD_CERTIFICATIONS })
  }
  catch(e){
    yield put({ type: END_LOAD_CERTIFICATIONS })
    // TODO -> throw global error!
  }
}

function* loadStatsSaga({type}) {
  
  try
  {
    const data = yield pixivus.stats();
    // console.log('++++++++++++++LoadStats:',data)
    if(data) {
      yield put(setStats(data))
      // console.log('++++++++++++++SetStats:DONE')
    }
    // console.log('++++++++++++++SetStats:SALIO')
    yield put({ type: END_LOAD_CERTIFICATIONS })
  }
  catch(e){
    // console.log('------------LoadStats:ERROR',e)
    yield put({ type: END_LOAD_CERTIFICATIONS })
    // TODO -> throw global error!
  }
}

function* initLoadCertifications () {
  // console.log( ' # core.INIT@certifications-saga ' )
  // yield put({type: core.ACTION_START, payload: { loadCertifications: 'Loading certifications'}})
  // yield call(loadCertificationsSaga)
  // yield put({type: core.ACTION_END, payload: 'loadCertifications'})
}

store.injectSaga('certifications', [
  // takeEvery(core.INIT, initLoadCertifications),
  takeEvery(LOAD_CERTIFICATIONS, loadCertificationsSaga),
  takeEvery(LOAD_STATS, loadStatsSaga),
]);

// Selectores - Conocen el stado y retornan la info que es necesaria
export const certifications   = (state) => state.certifications.certifications ;
export const isLoading        = (state) => state.certifications.is_loading
export const stats            = (state) => state.certifications.stats

// El reducer del modelo
const defaultState = {
    certifications:   [],
    filters:          [],
    stats:            {},
    is_loading:       false
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case LOAD_CERTIFICATIONS: 
      return { ...state
              , is_loading: true}
    case END_LOAD_CERTIFICATIONS: 
      return { ...state
              , is_loading: false}
    case SET_CERTIFICATIONS: 
      let new_certifications = state.certifications;
      new_certifications[action.payload.key] = action.payload.certifications;
      // console.log('REDUX::', action.payload.key, ' = ',action.payload.certifications);
      return  { ...state
                , certifications: new_certifications
                , is_loading: false}
    case LOAD_STATS:
      return { ...state
              , is_loading: true}
    case SET_STATS: 
      let stats = action.payload.data;
      // console.log('REDUX::SET_STATS:', stats)
      return  { ...state
                , stats: stats
                , is_loading: false}
    default: return state;
  }
}

store.injectReducer('certifications', reducer)