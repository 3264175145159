import { getLanguage } from '@app/services/localStorage';
import AppLocale from '@app/lang';
import * as globalCfg from '@app/configs/global';

import * as moment from 'moment';
import 'moment/locale/pt-br';
// import 'moment/locale/en';
import 'moment/locale/es';

export const getLocale = async () => {
  const lang = getLanguage();
  const lan_key = Object.keys(AppLocale).filter((key) => AppLocale[key].locale === lang);
  let the_key = globalCfg.language.simple;
  if (Array.isArray(lan_key) && lan_key.length > 0) the_key = lan_key[0];

  moment.locale(globalCfg.language.moment);

  AppLocale[the_key].messages = await AppLocale[the_key].getMessages();
  return AppLocale[the_key];
};
