/*
 * APP VERSION
 * DO NOT CHANGE FOLLOWING LINE!!!!!!!!
 */
const version = '1.0.102';

/*
 * ENV & BLOCKCHAIN CONFIGURATION!
 */
const ENV_DEV = 'dev';
const ENV_DEMO = 'demo';
const ENV_STAGING = 'staging';
const ENV_PROD = 'prod';

const EOS_TESTNET = 'eos_testnet';
const TELOS_TESTNET = 'telos_testnet';
const TELOS_MAINNET = 'telos_mainnet';

const env = process.env.REACT_APP_ENV || ENV_STAGING;

const BLOCKCHAIN_NETWORK = TELOS_MAINNET;

const language = {
  simple: 'es',
  extended: 'es-ES',
  moment: 'es',
};

const base_api_url = {
  [ENV_DEV]: 'http://localhost:3600',
  [ENV_DEMO]: 'https://pixivuscore-staging.herokuapp.com',
  [ENV_STAGING]: 'https://pixivuscore-staging.herokuapp.com',
  [ENV_PROD]: 'https://pixivuscore.herokuapp.com',
  // [ENV_PROD]     : 'https://cloud.pixivus.com'
};

const base_url = base_api_url[env];
const api_version = '/api/v1';

const pdf_url = env === ENV_PROD ? base_url : base_url;

const eosio_net = {
  [EOS_TESTNET]: {
    customers:
      'https://jungle.bloks.io/account/cristaltoken?loadContract=true&tab=Tables&account=cristaltoken&scope=cristaltoken&limit=100&table=customer',
    endpoint: 'https://jungle2.cryptolions.io:443',
    create_account: 'https://api.monitor.jungletestnet.io/#account',
    tx_url: 'https://eosauthority.com/transaction/',
    account_url: 'https://jungle.bloks.io/account/',
    info_link: 'https://jungle.bloks.io',
    info: 'EOS TESTNET',
    currency_symbol: 'ELOS',
  },
  [TELOS_TESTNET]: {
    customers:
      'https://telos-test.bloks.io/account/cristaltoken?loadContract=true&tab=Tables&account=cristaltoken&scope=cristaltoken&limit=100&table=customer',
    endpoint: 'https://testnet.telosusa.io',
    endpoint_ex: 'https://testnet.telosusa.io',
    history_endpoint: 'https://testnet.telosusa.io',
    create_account: 'https://app.telos.net/testnet/developers',
    tx_url: 'https://telos-test.bloks.io/transaction/',
    account_url: 'https://telos-test.bloks.io/account/',
    info_link: 'https://telos-test.bloks.io',
    info: 'TELOS TESTNET',
    currency_symbol: 'TLOS',
  },
  [TELOS_MAINNET]: {
    customers:
      'https://telos.bloks.io/account/cristaltoken?loadContract=true&tab=Tables&account=cristaltoken&scope=cristaltoken&limit=100&table=customer',
    endpoint: 'https://telos.greymass.com',
    endpoint_ex: 'https://telos.greymass.com',
    history_endpoint: 'https://telos.eoscafeblock.com',
    create_account: 'https://app.telos.net/accounts/add',
    tx_url: 'https://eosauthority.com/transaction/',
    account_url: 'https://eosauthority.com/account/',
    info_link: 'https://telos.eosx.io/',
    info: 'TELOS MAINNET',
    currency_symbol: 'TLOS',
  },
};

const pixivus = {
  account: 'pixivuscore1',
  contract: 'pixivuscore',
  table_hashes: 'pixihash',
  table_files: 'pixifile',
  action_save: 'save',
  action_erase: 'erase',
  graphql_endpoint: base_url + api_version + '/graphql',
  pdf_endpoint: pdf_url + api_version,
  lists_pagesize: 20,
  rest_endpoint: base_url + api_version,
  FILE_ATTACHMENT_KEY: 'pixivus_file_attachment',
  ACCOUNT_TYPE_GUEST: 'guest',
  ACCOUNT_TYPE_OWNER: 'owner',
  ACCOUNT_TYPE_SUPERADMIN: 'superadmin',
  HB4_DOMAIN: 'hb4',
  PAMPEANA_DOMAIN: 'pampeana',
  GLOBAL_DOMAIN: 'pixivus',
  PIXIVUS_DOMAIN: 'pixivus',
  account_types: () => [
    pixivus.ACCOUNT_TYPE_GUEST,
    pixivus.ACCOUNT_TYPE_OWNER,
    pixivus.ACCOUNT_TYPE_SUPERADMIN,
  ],
  faIconForAccountType: (account_type) => {
    const x = {
      [pixivus.ACCOUNT_TYPE_GUEST]: 'user',
      [pixivus.ACCOUNT_TYPE_OWNER]: 'user-shield',
      [pixivus.ACCOUNT_TYPE_SUPERADMIN]: 'user-astronaut',
    };
    return x[account_type] || 'user';
  },
  isGuest: (account_type) => pixivus.ACCOUNT_TYPE_GUEST == account_type,
  isOwner: (account_type) => pixivus.ACCOUNT_TYPE_OWNER == account_type,
  isSuperadmin: (account_type) => pixivus.ACCOUNT_TYPE_SUPERADMIN == account_type,
  buckets_permissions: {
    PERMISSION_TYPE_NONE: {
      value: 0,
      description: 'none',
    },
    PERMISSION_TYPE_READ: {
      value: 1,
      description: 'read',
    },
    PERMISSION_TYPE_POST: {
      value: 2,
      description: 'write',
    },
    PERMISSION_TYPE_ADMIN: {
      value: 4,
      description: 'admin',
    },
  },
  isAdminPerm: (perm) => pixivus.buckets_permissions.PERMISSION_TYPE_ADMIN.value == perm,
  isWritePerm: (perm) => pixivus.buckets_permissions.PERMISSION_TYPE_POST.value == perm,
  isReadPerm: (perm) => pixivus.buckets_permissions.PERMISSION_TYPE_READ.value == perm,
  getPermission: (perm) => {
    if (
      [
        pixivus.buckets_permissions.PERMISSION_TYPE_ADMIN.value.toString(),
        pixivus.buckets_permissions.PERMISSION_TYPE_ADMIN.description,
      ].includes(perm.toString())
    )
      return pixivus.buckets_permissions.PERMISSION_TYPE_ADMIN;
    if (
      [
        pixivus.buckets_permissions.PERMISSION_TYPE_POST.value.toString(),
        pixivus.buckets_permissions.PERMISSION_TYPE_POST.description,
      ].includes(perm.toString())
    )
      return pixivus.buckets_permissions.PERMISSION_TYPE_POST;
    if (
      [
        pixivus.buckets_permissions.PERMISSION_TYPE_READ.value.toString(),
        pixivus.buckets_permissions.PERMISSION_TYPE_READ.description,
      ].includes(perm.toString())
    )
      return pixivus.buckets_permissions.PERMISSION_TYPE_READ;
    return pixivus.buckets_permissions.PERMISSION_TYPE_NONE;
  },
  buckets_status: {
    STATUS_OK: 'status_ok',
    STATUS_ARCHIVED: 'status_archived',
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyDf2uraSVBT-I5gGOCF-CFT8rbkVocxzKY',
  MAX_FILES_PER_POST: 5,
};

const eos = {
  ...eosio_net[BLOCKCHAIN_NETWORK],

  account_keys_url_postfix: '#keys',
  getBlockExplorerTxLink: (tx_id) => {
    return eos.tx_url + tx_id;
  },
  getBlockExplorerAccountLink: (account_name) => {
    return eos.account_url + (account_name ? account_name : pixivus.account);
  },
  push: {
    retries: 3,
    use_options: true,
    options: {
      blocksBehind: 3,
      expireSeconds: 60,
    },
    breakable_error_codes: [3081001],
  },
};

const firebase = {
  apiKey: 'AIzaSyBM9VQoNpJzWHgClcORuVi9D7tjqeD6mVE',
  authDomain: 'pixivus-mail-sender.firebaseapp.com',
  databaseURL: 'https://pixivus-mail-sender.firebaseio.com',
  projectId: 'pixivus-mail-sender',
  storageBucket: 'pixivus-mail-sender.appspot.com',
  messagingSenderId: '659525980935',
  appId: '1:659525980935:web:56061ccce66bf9773c2ba2',
  measurementId: 'G-J5RK60YFBC',
  vapid: 'BPMzvgsnDzNbR88qZzSET2x70AuieiUsWjPZ_yYfWFW11l2Rr-RWbCEt5wJ7qZACr7gMuc2z2nteMAAwpYGYTnQ',
};

const recaptcha = { public_key: '6LeNLtkZAAAAAA8HGL2gwnfH9x-qr00F638Y4ZGU' };

export { language, eos, env, version, pixivus, firebase, recaptcha };
