import React, { Component } from 'react'
import { Button, Modal } from 'antd';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import * as menuRedux from '@app/redux/models/menu'
import * as loginRedux from '@app/redux/models/login';

import * as utils from '@app/utils/utils';
import './right_content.less';

import SelectLanguage from '@app/components/Header/SelectLang';
import UnvalidatedAccountWidget from '@app/components/Header/unvalidated_account';
import NotificationWidget from '@app/components/Header/NoticeIconView';
import * as components_helper from '@app/components/helper';

import MobilePager from '@app/components/Header/mobile_pager';

import { injectIntl } from "react-intl";
import { LogoutOutlined } from '@ant-design/icons';

class PixivusHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: props.isMobile,
      referrer: props.referrer,
      accountProfile: props.accountProfile
    }
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let new_state = {};

    if (this.props.isMobile != prevProps.isMobile)
      new_state = { ...new_state, isMobile: this.props.isMobile }

    if (this.props.referrer != prevProps.referrer)
      new_state = { ...new_state, referrer: this.props.referrer }

    if (this.props.menuIsCollapsed != prevProps.menuIsCollapsed)
      new_state = { ...new_state, menuIsCollapsed: this.props.menuIsCollapsed }

    if (!utils.objectsEqual(this.state.accountProfile, this.props.accountProfile))
      new_state = { ...new_state, accountProfile: this.props.accountProfile }

    if (prevProps.isFetching != this.props.isFetching) {
      new_state = { ...new_state, isFetching: this.props.isFetching }
    }

    if (!utils.arraysEqual(prevProps.getErrors, this.props.getErrors)) {
      const ex = this.props.getLastError;
      new_state = {
        ...new_state,
        getErrors: this.props.getErrors,
        result: ex ? 'error' : undefined,
        error: ex ? JSON.stringify(ex) : null
      }
      if (ex)
        components_helper.notif.exceptionNotification(this.props.intl.formatMessage({ id: 'errors.occurred_title' }), ex, this.props.clearAll, this.props.intl)
    }

    if (!utils.arraysEqual(prevProps.getResults, this.props.getResults)) {
      const lastResult = this.props.getLastResult;
      new_state = {
        ...new_state,
        getResults: this.props.getResults,
        result: lastResult ? 'ok' : undefined,
        result_object: lastResult
      };
      if (lastResult) {
        components_helper.notif.successNotification(this.props.intl.formatMessage({ id: 'success.oper_completed_succ' }), undefined, this.props.clearAll)
      }
    }


    if (Object.keys(new_state).length > 0)
      this.setState(new_state);
  }

  toggle = () => {
    this.props.collapseMenu(!this.props.menuIsCollapsed);
  };

  accountToString(account) {
    return JSON.stringify(account);
  }


  handleLogout() {
    const that = this;
    const { formatMessage } = this.props.intl;
    Modal.confirm({
      title: formatMessage({ id: 'global.logout_title' }),
      content: formatMessage({ id: 'global.logout_message' }),
      onOk() {
        that.props.logout();
      }
    });
  }

  render() {
    let header_content;
    const { isMobile, accountProfile } = this.state;

    if (isMobile) {
      header_content = <MobilePager redux_events={true} />
    } else {
      header_content = (
        <div className="right">
          <div className="header_element_container">
            {accountProfile && !accountProfile.email_confirmed && <UnvalidatedAccountWidget />}
            <NotificationWidget />
            <Button style={{ marginLeft: '10px', marginRight: '10px' }} icon={<LogoutOutlined />} onClick={this.handleLogout} size="small">
              {this.props.intl.formatMessage({ id: 'global.logout' })}
            </Button>
            <SelectLanguage icon_style={{ marginRight: '8px' }} />
          </div>
        </div>
      );
    }

    return (
      <div className="ant-pro-global-header">
        {header_content}
      </div>
    )
  }

}

export default connect(
  (state) => ({
    menuIsCollapsed: menuRedux.isCollapsed(state),
    isMobile: menuRedux.isMobile(state),
    accountProfile: loginRedux.accountProfile(state),
  }),
  (dispatch) => ({
    logout: bindActionCreators(loginRedux.logout, dispatch),
    collapseMenu: bindActionCreators(menuRedux.collapseMenu, dispatch),
  })
)(injectIntl(PixivusHeader))