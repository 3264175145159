import { takeEvery, put } from '@redux-saga/core/effects';
import { store } from '../configureStore';
import * as pixivus from '@app/services/pixivus';
import * as core from './core';

// Constantes
const LOAD_BUCKETS = 'buckets/LOAD_BUCKETS';
const END_LOAD_BUCKETS = 'buckets/END_LOAD_BUCKETS';
const SET_BUCKETS = 'buckets/SET_BUCKETS';

const LOAD_BUCKET_PROFILE = 'buckets/LOAD_BUCKET_PROFILE';
const END_LOAD_BUCKET_PROFILE = 'buckets/END_LOAD_BUCKET_PROFILE';
const SET_BUCKET_PROFILE = 'buckets/SET_BUCKET_PROFILE';
const LOADING_BUCKET = 'buckets/LOADING_BUCKET';

const CREATE_OR_UPDATE_BUCKET = 'buckets/CREATE_OR_UPDATE_BUCKET';
const DOING_CREATE_OR_UPDATE_BUCKET = 'buckets/DOING_CREATE_OR_UPDATE_BUCKET';
const CREATE_OR_UPDATE_BUCKET_RESULT = 'buckets/CREATE_OR_UPDATE_BUCKET_RESULT';

const CLEAR_RESULT = 'buckets/CLEAR_RESULT';

const LOAD_MORE_BUCKETS = 'buckets/LOAD_MORE_BUCKETS';
const SET_MORE_BUCKETS = 'buckets/SET_MORE_BUCKETS';

const CREATE_OR_UPDATE_CS = 'buckets/CREATE_OR_UPDATE_CS';
const DOING_CREATE_OR_UPDATE_CS = 'buckets/DOING_CREATE_OR_UPDATE_CS';
const CREATE_OR_UPDATE_CS_RESULT = 'buckets/CREATE_OR_UPDATE_CS_RESULT';

const CREATE_OR_UPDATE_BR = 'buckets/CREATE_OR_UPDATE_BR';
const DOING_CREATE_OR_UPDATE_BR = 'buckets/DOING_CREATE_OR_UPDATE_BR';
const CREATE_OR_UPDATE_BR_RESULT = 'buckets/CREATE_OR_UPDATE_BR_RESULT';

// Creadores de acciones (se pueden usar desde los componentes)
export const load = (filter, key) => ({ type: LOAD_BUCKETS, payload: { filter, key } });
export const set = (buckets, filter, key) => ({
  type: SET_BUCKETS,
  payload: { buckets, filter, key },
});
export const loadProfile = (_id) => ({ type: LOAD_BUCKET_PROFILE, payload: { _id } });
export const createOrUpdate = (bucket) => ({ type: CREATE_OR_UPDATE_BUCKET, payload: { bucket } });
export const clearResult = () => ({ type: CLEAR_RESULT });

export const loadMore = (filter, key) => ({ type: LOAD_MORE_BUCKETS, payload: { filter, key } });
export const setMore = (buckets, filter, key) => ({
  type: SET_MORE_BUCKETS,
  payload: { buckets, filter, key },
});

function* loadBucketsSaga({ type, payload }) {
  const { filter, key } = payload;
  if (!filter || !key) {
    return;
  }

  try {
    const data = yield pixivus.buckets(filter);
    if (data) {
      yield put(set(data, filter, key));
    }

    yield put({ type: END_LOAD_BUCKETS });
  } catch (e) {
    yield put({ type: END_LOAD_BUCKETS });
  }
}

function* loadMoreBucketsSaga({ type, payload }) {
  const { filter, key } = payload;
  if (!filter || !key) return;

  try {
    const data = yield pixivus.buckets(filter);
    if (data) {
      yield put(setMore(data, filter, key));
    }

    yield put({ type: END_LOAD_BUCKETS });
  } catch (e) {
    yield put({ type: END_LOAD_BUCKETS });
    // TODO -> throw global error!
  }
}

function* loadBucketProfileSaga({ type, payload }) {
  const { _id } = payload;

  if (!_id) {
    return;
  }

  yield put({ type: LOADING_BUCKET });

  try {
    const data = yield pixivus.bucketProfile(_id);
    console.log('BUCKET-PROFILE::REDUX::', _id, data);
    if (data) {
      yield put({ type: SET_BUCKET_PROFILE, payload: { bucket_profile: data } });
    }

    yield put({ type: END_LOAD_BUCKET_PROFILE });
  } catch (e) {
    yield put({ type: END_LOAD_BUCKETS });
    // TODO -> throw global error!
  }
}

function* createOrUpdateBucketSaga({ type, payload }) {
  const { bucket } = payload;
  if (!bucket) return;
  yield put({ type: DOING_CREATE_OR_UPDATE_BUCKET });
  try {
    const data = yield pixivus.createOrUpdateBucket(bucket);
    yield put({
      type: CREATE_OR_UPDATE_BUCKET_RESULT,
      payload: { status: 'success', object: data },
    });
  } catch (e) {
    yield put({ type: CREATE_OR_UPDATE_BUCKET_RESULT, payload: { status: 'error', object: e } });
    // TODO -> throw global error!
  }
}

function* initLoadBuckets() {}

store.injectSaga('buckets', [
  takeEvery(LOAD_BUCKETS, loadBucketsSaga),
  takeEvery(LOAD_MORE_BUCKETS, loadMoreBucketsSaga),
  takeEvery(LOAD_BUCKET_PROFILE, loadBucketProfileSaga),
  takeEvery(CREATE_OR_UPDATE_BUCKET, createOrUpdateBucketSaga),
  takeEvery(core.INIT, initLoadBuckets),
]);

// Selectores - Conocen el stado y retornan la info que es necesaria
export const buckets = (state) => state.buckets.buckets;
export const isLoading = (state) => state.buckets.is_loading;
export const bucketProfile = (state) => state.buckets.bucket_profile;
export const isPushing = (state) => state.buckets.is_pushing;
export const result = (state) => state.buckets.result;

// El reducer del modelo
const defaultState = {
  buckets: [],
  filters: [],
  is_loading: false,
  bucket_profile: null,
  is_pushing: false,
  result: null,
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case LOAD_BUCKETS:
      return { ...state, is_loading: true };
    case LOAD_MORE_BUCKETS:
      return { ...state, is_loading: true };
    case END_LOAD_BUCKET_PROFILE:
      return { ...state, is_loading: false };
    case END_LOAD_BUCKETS:
      return { ...state, is_loading: false };
    case SET_BUCKETS:
      let new_buckets = state.buckets;
      new_buckets[action.payload.key] = action.payload.buckets;
      return { ...state, buckets: new_buckets, is_loading: false };
    case SET_MORE_BUCKETS:
      let more_buckets = state.buckets;
      more_buckets[action.payload.key] = [
        ...(more_buckets[action.payload.key] || []),
        ...action.payload.buckets,
      ];
      return { ...state, buckets: more_buckets, is_loading: false };
    case SET_BUCKET_PROFILE:
      return { ...state, bucket_profile: action.payload.bucket_profile, is_loading: false };
    case CREATE_OR_UPDATE_BUCKET:
      return {
        ...state,
        is_pushing: true,
      };
    case LOADING_BUCKET:
      return {
        ...state,
        is_loading: true,
      };
    case DOING_CREATE_OR_UPDATE_BUCKET:
      return {
        ...state,
        is_pushing: true,
      };
    case CREATE_OR_UPDATE_BUCKET_RESULT:
      return {
        ...state,
        result: {
          status: action.payload.status,
          object: action.payload.object,
        },
        is_pushing: false,
      };
    case DOING_CREATE_OR_UPDATE_CS:
      return {
        ...state,
        is_pushing: true,
      };
    case CREATE_OR_UPDATE_CS_RESULT:
      return {
        ...state,
        result: {
          status: action.payload.status,
          object: action.payload.object,
        },
        is_pushing: false,
      };
    case DOING_CREATE_OR_UPDATE_BR:
      return {
        ...state,
        is_pushing: true,
      };
    case CREATE_OR_UPDATE_BR_RESULT:
      return {
        ...state,
        result: {
          status: action.payload.status,
          object: action.payload.object,
        },
        is_pushing: false,
      };
    case CLEAR_RESULT:
      return {
        ...state,
        result: null,
        is_pushing: false,
      };
    default:
      return state;
  }
}

store.injectReducer('buckets', reducer);
