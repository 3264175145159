import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import * as loginRedux from '@app/redux/models/login';
import * as menuRedux from '@app/redux/models/menu';
import { injectIntl } from 'react-intl';

import * as pixivus from '@app/services/pixivus';

import { ResizeableTable } from '@app/components/widgets/resizable_columns';

import { DropboxOutlined, SafetyCertificateOutlined } from '@ant-design/icons';

import { Card, Tag, Tooltip, Spin, List, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as globalCfg from '@app/configs/global';
import * as utils from '@app/utils/utils';
export const VIEW_MODE_LIST = 'list';
export const VIEW_MODE_CARD = 'card';

const BucketList = (props) => {
  const default_view_mode = props.view_mode || props.isMobile ? VIEW_MODE_CARD : VIEW_MODE_LIST;
  const [view_mode, setViewMode] = useState(default_view_mode);

  const [buckets, setBuckets] = useState(props.buckets);
  const [loading, setLoading] = useState(props.loading);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [intl, setIntl] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 0, total: 0 });

  useEffect(() => {
    const x = (buckets || []).length;
    setBuckets(props.buckets);
    setPagination({ pageSize: x, total: x });
  }, [buckets, props.buckets]);

  const can_get_more = true;

  useEffect(() => {
    const { formatMessage } = props.intl;
    let _intl = {};
    _intl.column_title_timestamp = formatMessage({ id: 'pages.home.column_title_timestamp' });
    _intl.column_title_hash = formatMessage({ id: 'pages.home.column_title_hash' });
    _intl.column_title_file = formatMessage({ id: 'pages.home.column_title_file' });
    _intl.action_button_view_transaction = formatMessage({
      id: 'pages.home.action_button_view_transaction',
    });
    _intl.column_title_created_at = formatMessage({ id: 'pages.home.column_title_created_at' });
    _intl.num_post_by_bucket = formatMessage({ id: 'pages.buckets.num_post_by_bucket' });
    _intl.num_cats_by_bucket = formatMessage({ id: 'pages.buckets.num_cats_by_bucket' });
    _intl.archived_bucket_tooltip = formatMessage({ id: 'pages.buckets.archived_bucket_tooltip' });

    _intl.perm_none = formatMessage({ id: 'global.bucket_permission.none' });
    _intl.perm_none_color = formatMessage({ id: 'global.bucket_permission.color.none' });
    _intl.perm_read = formatMessage({ id: 'global.bucket_permission.read' });
    _intl.perm_read_color = formatMessage({ id: 'global.bucket_permission.color.read' });
    _intl.perm_write = formatMessage({ id: 'global.bucket_permission.write' });
    _intl.perm_write_color = formatMessage({ id: 'global.bucket_permission.color.write' });
    _intl.perm_post = formatMessage({ id: 'global.bucket_permission.post' });
    _intl.perm_post_color = formatMessage({ id: 'global.bucket_permission.color.post' });
    _intl.perm_admin = formatMessage({ id: 'global.bucket_permission.admin' });
    _intl.perm_admin_color = formatMessage({ id: 'global.bucket_permission.color.admin' });

    _intl.columns_bucket_name = formatMessage(
      { id: 'pages.buckets.columns.bucket_name' },
      { bucketType: props.bucketType?.name }
    );
    _intl.columns_user_role = formatMessage({ id: 'pages.buckets.columns.user_role' });
    _intl.columns_bucket_created_at = formatMessage({
      id: 'pages.buckets.columns.bucket_created_at',
    });
    _intl.columns_bucket_posts = formatMessage({ id: 'pages.buckets.columns.bucket_posts' });
    _intl.columns_bucket_categories = formatMessage({
      id: 'pages.buckets.columns.bucket_categories',
    });
    _intl.columns_bucket_description = formatMessage({
      id: 'pages.buckets.columns.bucket_description',
    });

    _intl.load_more = formatMessage({ id: 'global.list.load_more' });

    _intl.action_button_view_bucket = formatMessage({
      id: 'pages.buckets.action_button_view_bucket',
    });
    _intl.action_button_view_posts = formatMessage({
      id: `pages.bucket.action_button_view_posts`,
    });

    _intl.action_button_view_dashboard = formatMessage({
      id: 'pages.bucket.action_button_view_dashboard',
    });
    _intl.action_button_new_post = formatMessage({ id: 'pages.buckets.action_button_new_post' });

    setIntl(_intl);
  }, [props.bucketType, props.intl]);

  useEffect(() => {
    if (props.view_mode) setViewMode(props.view_mode);
  }, [props.view_mode]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setIsMobile(props.isMobile);
  }, [props.isMobile]);

  const getBucketLink = (item) => {
    return (
      <Button
        type="link"
        key={`bucket_${item._id}`}
        size="small"
        onClick={() => onCallback('bucket', item)}
      >
        <DropboxOutlined /> {intl.action_button_view_bucket}
      </Button>
    );
  };

  const getPostsLink = (item) => {
    return (
      <Button
        type="link"
        key={`posts_${item._id}`}
        size="small"
        onClick={() => onCallback('posts', item)}
        icon={<SafetyCertificateOutlined />}
      >
        &nbsp;{intl.action_button_view_posts}
      </Button>
    );
  };

  const getNewPostsLink = (item) => {
    if (!bucketIsWritable(item)) return null;
    return (
      <Button
        key={`new_posts_${item._id}`}
        size="small"
        onClick={() => onCallback('new_post', item)}
      >
        &nbsp;{intl.action_button_new_post}
      </Button>
    );
  };

  const bucketIsWritable = (bucket) => {
    const _bucket = props.accountBuckets.find((bucketPerm) => bucketPerm.bucket_id == bucket._id);
    if (!_bucket) return false;
    const perm = bucket.permission;
    return parseInt(perm) > globalCfg.pixivus.buckets_permissions.PERMISSION_TYPE_READ.value;
  };

  const rowCallback = (item) => onCallback(undefined, item);

  const onCallback = (_type, item) => {
    if (typeof props.callback === 'function') props.callback(_type, item);
  };

  const fireLoadMoreCallback = () => {
    if (!props.loadMoreCallback || typeof props.loadMoreCallback != 'function') return;
    props.loadMoreCallback(pagination);
  };

  const renderFooter = () => {
    return (
      <Button
        key={'load-more-data_'}
        disabled={!can_get_more}
        onClick={() => fireLoadMoreCallback()}
      >
        {intl.load_more}
      </Button>
    );
  };

  const columns = () => [
    {
      title: intl.columns_bucket_name,
      key: 'name',
      width: 100,
      align: 'left',
      render: (data, record) => {
        return (
          <>
            {record.name}
            <br />
            {!!record._type && <Tag color="green">{record._type?.toUpperCase()}</Tag>}
          </>
        );
      },
    },
    {
      title: intl.columns_user_role,
      key: 'user_role',
      width: 75,
      align: 'left',
      render: (data, record) => getPermission(record),
    },
    {
      title: intl.columns_bucket_created_at,
      dataIndex: 'created_at',
      key: 'created_at',
      width: 75,
      align: 'left',
      render: (data, record) => (
        <Tooltip title={pixivus.formatUnix2(record.created_at || 0)}>
          <span>{pixivus.unixDateToLocale(record.created_at || 0)}</span>
        </Tooltip>
      ),
    },
    {
      title: intl.columns_bucket_posts,
      dataIndex: 'numPosts',
      key: 'numPosts',
      width: 50,
      align: 'right',
      render: (data, record) => record.numPosts,
    },
    {
      title: intl.columns_bucket_categories,
      key: 'categories',
      width: 50,
      align: 'right',
      render: (data, record) => (record.categories || []).length,
    },
    {
      title: intl.columns_bucket_description,
      key: 'description',
      width: 100,
      align: 'left',
      render: (data, record) => record.description,
    },
    {
      key: 'actions_1',
      width: 75,
      align: 'left',
      render: (data, record) => (
        <div className="td_flex_row">
          {getPostsLink(record)} {getNewPostsLink(record)} {getBucketLink(record)}
        </div>
      ),
    },
  ];

  const getStatus = (item) => {
    if (item.status == globalCfg.pixivus.buckets_status.STATUS_OK) return null;
    return (
      <Tooltip title={intl.archived_bucket_tooltip} key={item._id + 'archived_'}>
        <FontAwesomeIcon icon="archive" color="black" />
      </Tooltip>
    );
    //
  };

  const getPermission = (item) => {
    let perm_type = globalCfg.pixivus.getPermission('');
    let extra = null;
    if (!props.isAdmin) {
      const bucket = props.accountBuckets.find((bucketPerm) => bucketPerm.bucket_id == item._id);
      if (!bucket) return <Tag color="gray">{'ERROR'}</Tag>;
      //
      perm_type = globalCfg.pixivus.getPermission(bucket.permission);
    } else {
      extra = 'SuperAdmin';
    }
    return (
      <Tag color={intl[`perm_${perm_type.description}_color`]}>
        {extra || intl[`perm_${perm_type.description}`]}
      </Tag>
    );
  };

  const renderItem = (item) => {
    if (isMobile)
      return (
        <List.Item key={item._id} className="bucket">
          <Card title={item.name} className="bucket_content_wrapper">
            <div className="key_value">
              <div className="key">{intl.columns_user_role}:</div>
              <div className="value">{getPermission(item)}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.columns_bucket_created_at}:</div>
              <div className="value">{pixivus.formatUnix2(item.created_at || 0)}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.num_post_by_bucket}:</div>
              <div className="value">{item.numPosts}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.num_cats_by_bucket}:</div>
              <div className="value">{(item.categories || []).length}</div>
            </div>
            <div className="key_value">
              <div className="key">{intl.columns_bucket_description}</div>
              <div className="value">{item.description}</div>
            </div>
            <div className="key_value">
              <div className="value buttons">
                {getPostsLink(item)}
                {getNewPostsLink(item)}
                {getBucketLink(item)}{' '}
              </div>
            </div>
          </Card>
        </List.Item>
      );

    return (
      <List.Item key={item._id} className="bucket">
        <div className="top">
          <div className="left">
            <div className="title">{item.name}</div>
            <div className="description">{item.description}</div>
          </div>
          <div className="right">
            <div className="permission">{getPermission(item)}</div>
            <div className="status">{getStatus(item)}</div>
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <span className="created_at">{pixivus.unixDateToLocale(item.created_at || 0)}</span>
          </div>
          <div className="right">
            <div className="numPosts">
              {intl.num_post_by_bucket}:&nbsp;{item.numPosts}
            </div>
            <div className="numCats">
              {intl.num_cats_by_bucket}:&nbsp;{(item.categories || []).length}
            </div>

            <div className="action">
              &nbsp; {getPostsLink(item)} &nbsp; {getNewPostsLink(item)} &nbsp;{' '}
              {getBucketLink(item)}
            </div>
          </div>
        </div>
      </List.Item>
    );
  };

  if (view_mode == VIEW_MODE_CARD)
    return (
      <Spin spinning={loading} delay={50} tip="Loading">
        <List header={null} dataSource={buckets} renderItem={renderItem} className="buckets"></List>
      </Spin>
    );

  const paginationEX = !buckets
    ? { pageSize: 0, total: 0 }
    : { pageSize: buckets.length, total: buckets.length };

  return (
    <ResizeableTable
      key={'table__buckets'}
      rowKey={(record) => record._id}
      loading={loading}
      columns_def={columns()}
      dataSource={buckets}
      footer={() => renderFooter()}
      pagination={paginationEX}
      scroll={{ x: 950 }}
      rowClassName={(record, rowIndex) => {
        if (record.unconfirmed == true) return 'unconfirmed';
        return rowIndex % 2 == 0 ? 'even' : 'odd';
      }}
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: (event) => {
            rowCallback && rowCallback(record);
          },
        };
      }}
    />
  );
};

export default connect(
  (state) => ({
    isMobile: menuRedux.isMobile(state),
    accountName: loginRedux.accountName(state),
    isAdmin: loginRedux.isAdmin(state),
    accountBuckets: loginRedux.accountBuckets(state),
  }),
  (dispatch) => ({})
)(injectIntl(BucketList));
