import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as accountsRedux from '@app/redux/models/accounts';
import * as loginRedux from '@app/redux/models/login';

import * as components_helper from '@app/components/helper';

import { RedoOutlined } from '@ant-design/icons';

import { Button, AutoComplete, Input, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { injectIntl } from 'react-intl';

class AutocompleteAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      size: props.size,
      value: props.value,
      data: [],
      validation_rule: props.validation_rule,
      selected: undefined,
      exclude_list: props.exclude_list,
      readOnly: props.readOnly || false,
      filter: props.filter || null,
      without_icon: props.without_icon,
      label: props.label,
      not_required: props.not_required,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.setAccounts = this.setAccounts.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.reset = this.reset.bind(this);
    this.onAutocompleteBlur = this.onAutocompleteBlur.bind(this);
  }

  componentDidMount() {
    this.setAccounts();

    if (typeof this.props.onRef === 'function') {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onRef === 'function') this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        filter: this.props.filter || false,
        readOnly: this.props.readOnly || false,
        without_icon: this.props.without_icon,
        label: this.props.label,
        not_required: this.props.not_required,
        size: this.props.size,
      });
    }

    if (prevProps.accounts !== this.props.accounts) {
      this.setAccounts();
    }

    if (prevProps.label !== this.props.label) {
      this.setState({ label: this.props.label });
    }

    if (prevProps.exclude_list !== this.props.exclude_list) {
      this.setState(
        {
          exclude_list: this.props.exclude_list,
        },
        () => {
          this.setAccounts();
        }
      );
    }
  }

  setAccounts = () => {
    const { filter, exclude_list } = this.props;

    const _filter_arr = filter ? (!Array.isArray(filter) ? [filter] : filter) : null;
    const filter_arr = _filter_arr ? _filter_arr : null;
    const my_accounts = this.props.accounts.filter(
      (acc) =>
        (!filter_arr || filter_arr.includes(acc.account_type)) &&
        !(exclude_list || []).includes(acc.account_name)
    );

    this.setState({
      filter: this.props.filter,
      data: my_accounts,
    });
  };

  loadAccounts = (e) => {
    if (typeof e === 'object' && typeof e.preventDefault === 'function') e.preventDefault();
    this.props.loadAccounts();
    this.reset();
  };

  handleChange = (value) => {
    console.log(' ::handleChange', value);
  };

  handleSearch = (value) => {
    console.log(' ::handleSearch', value);
  };

  handleSelect = (value, option) => {
    this.setState({
      selected: value,
      value: value,
      fetching: false,
    });

    const exists = this.props.accounts.filter((account) => account.account_name == value);
    if (!exists || exists.length == 0) {
      components_helper.notif.errorNotification(
        this.props.intl.formatMessage({ id: 'errors.select_account_from_list' })
      );
      this.triggerChange(null);
      return;
    }
    this.triggerChange(value);
  };

  triggerChange = (changedValue, _id) => {
    // Should provide an event to pass value to Form.
    const { callback } = this.props;
    if (typeof callback === 'function') {
      callback(changedValue, _id);
      setTimeout(() => {
        this.reset();
      }, 100);
    }
  };

  reset = () => {
    this.props.form.setFieldsValue({ [this.props.name]: '' });
  };

  onChange = (o) => {
    console.log(o);
  };

  renderAccount = (item) => {
    const { formatMessage } = this.props.intl;
    return {
      label: (
        <>
          {item.account_name}
          <span className="certain-search-item-count">
            @{formatMessage({ id: `global.account_type.${item.account_type}` })}
          </span>
        </>
      ),
      value: item.account_name,
    };
  };

  onAutocompleteBlur = () => {
    if (!this.state.selected) this.reset();
  };

  render = () => {
    const { formatMessage } = this.props.intl;
    const { form } = this.props;
    if (!form) return null;

    const { without_icon, readOnly, value, data, label, not_required, validation_rule } =
      this.state;
    const { isLoading, name } = this.props;
    let selector = null;

    if (readOnly) {
      selector = (
        <div className="ui-row__col ui-row__col--content">
          <div className="ui-info-row__content">
            <div className="ui-info-row__title">
              <b>value</b>
            </div>
            <div className="ui-info-row__details">
              <ul>
                <li>@{value}</li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      selector = (
        <Form.Item
          label={label}
          name={name}
          rules={[
            {
              required: !not_required,
              message: !not_required
                ? formatMessage({
                    id: 'components.AutocompleteAccount.index.choose_account_message',
                  })
                : undefined,
              validator: validation_rule,
            },
          ]}
        >
          <AutoComplete
            mode={this.props.mode || 'default'}
            onBlur={this.onAutocompleteBlur}
            backfill
            options={data.map(this.renderAccount)}
            onSelect={this.handleSelect}
            placeholder={formatMessage({
              id: 'components.AutocompleteAccount.index.placeholder',
            })}
            filterOption={(inputValue, option) => {
              // console.log('>>filterOption >> ',inputValue, option);
              return option.value?.indexOf(inputValue) !== -1;
            }}
            style={{ minWidth: 250 }}
          >
            <Input
              suffix={
                <Button
                  loading={isLoading}
                  type="link"
                  icon={<RedoOutlined />}
                  className="redoButton"
                  title={formatMessage({ id: 'components.AutocompleteAccount.index.title' })}
                  onClick={this.loadAccounts}
                ></Button>
              }
            />
          </AutoComplete>
        </Form.Item>
      );
    }
    //
    if (without_icon === true) return selector;

    return (
      <div className="col_icon_widget money-transfer__row row-complementary row-complementary-bottom money-transfer__select">
        <div className="col_icon badge badge-extra-small badge-circle addresse-avatar ">
          <span className="picture">
            <FontAwesomeIcon icon="user" size="lg" color="black" />
          </span>
        </div>
        <div className="col_widget money-transfer__input money-transfer__select">{selector}</div>
      </div>
    );
  };
}

export default connect(
  (state) => ({
    accounts: accountsRedux.accounts(state),
    isLoading: accountsRedux.isLoading(state),
    accountName: loginRedux.accountName(state),
  }),
  (dispatch) => ({
    loadAccounts: bindActionCreators(accountsRedux.loadAccounts, dispatch),
  })
)(injectIntl(AutocompleteAccount));
