import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import * as menuRedux from '@app/redux/models/menu';

import { CreditCardOutlined, FilterOutlined, TableOutlined } from '@ant-design/icons';

import { Button, Radio } from 'antd';

import { injectIntl } from 'react-intl';

import { VIEW_MODE_LIST, VIEW_MODE_CARD, VIEW_MODE_MAP } from '@app/components/posts/list';
export { VIEW_MODE_LIST, VIEW_MODE_CARD, VIEW_MODE_MAP };

const BucketsMobileHeader = (props) => {
  const default_view_mode = props.isMobile ? VIEW_MODE_CARD : VIEW_MODE_LIST;
  const [view_mode, setViewMode] = useState(props.view_mode || default_view_mode);

  const [intl, setIntl] = useState({});
  useEffect(() => {
    const { formatMessage } = props.intl;
    let _intl = {};
    _intl.search = formatMessage({ id: 'global.search' });
    _intl.filter = formatMessage({ id: 'global.filter' });
    setIntl(_intl);
  }, []);

  const onEvent = (view_mode, search_click, filter_click) => {
    if (typeof props.callback !== 'function') return;
    props.callback(view_mode, search_click, filter_click);
  };

  const onViewModeChange = (e) => {
    e.preventDefault();
    onEvent(e.target.value, null, null);
  };

  return (
    <div className="posts_mobile_header">
      <div className="left_buttons">
        <Radio.Group
          key="viewmode"
          defaultValue={view_mode}
          buttonStyle="solid"
          onChange={onViewModeChange}
        >
          <Radio.Button key="viewmode_list" value={VIEW_MODE_LIST}>
            <TableOutlined />
          </Radio.Button>
          <Radio.Button key="viewmode_card" value={VIEW_MODE_CARD}>
            <CreditCardOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="right_buttons">
        <Button
          key={'_y_' + Math.random()}
          size="default"
          onClick={() => onEvent(null, null, true)}
          icon={<FilterOutlined />}
        >
          {intl.filter}
        </Button>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isMobile: menuRedux.isMobile(state),
  }),
  (dispatch) => ({})
)(injectIntl(BucketsMobileHeader));
