import React, { Component } from 'react';
import { Tag, message } from 'antd';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { injectIntl } from "react-intl";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { withRouter } from "react-router-dom";

// import * as request_helper from '@app/components/TransactionCard/helper';

import * as messagingRedux from '@app/redux/models/messaging'
import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login'

import * as utils from '@app/utils/utils';
import * as signatureConfig from '@app/components/signature/signatureConfig';
import * as globalCfg from '@app/configs/global';

// import * as NoticeIcon from '@app/components/NoticeIcon';
import NoticeIcon, {Tab as NoticeIconTab} from './NoticeIcon';
import './index.less';


class GlobalHeaderRight extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        signatures : []
    };
  }

  componentDidMount() {
    const that = this;
      setTimeout(()=> {
        that.props.loadUnreadNotifications(that.props.accountName)
      } ,10);

  }

  componentDidUpdate(prevProps, prevState) 
  {
    let new_state = {};
    if(!utils.arraysEqual(this.state.signatures, this.props.signatures))
    {
      new_state = {...new_state, signatures: this.props.signatures};
    }

    if(Object.keys(new_state).length>0)      
      this.setState(new_state);
  }

  changeReadState = (clickedItem) => {
    // message.success(`${this.props.intl.formatMessage({ id: 'component.noticeIcon.read' })}`);
    
    const { _id } = clickedItem.signature.post;
    // this.props.onReadMessage(_id);
    const that = this;
    setTimeout(()=> {
      this.props.history.push({
        pathname: `/common/post_details/${_id}`
        , state: { postId:_id}
      });
    } ,250);

  };

  // handleNoticeClear = (title, key) => {
  //   // const { dispatch } = this.props;
  //   message.success(`${this.props.intl.formatMessage({ id: 'component.noticeIcon.cleared' })} ${title}`);
  // };

  handleNoticeClear = (_list) => {
    message.success(`${this.props.intl.formatMessage({ id: 'component.noticeIcon.cleared' })}`);
    this.props.clearMessages(_list.map(msg=>msg.message._id));
  };


  getCircledTypeIcon = (__icon__, smaller) => {
  
    
    const my_icon               =  {icon:__icon__,         rotation: 0,  color:{primary: '#ffd606' /*amarillo*/      , secondary:'#fcf4c7'}, style: {}};
    const my_icon_color_primary = 'rgba(0,0,0,0.6)';
    const className             = smaller
      ?'ui-avatar__content--smaller circled_action_type flex_center'
      :'ui-avatar__content--small circled_action_type flex_center';
    const style     = {border: `0.0625em solid ${my_icon_color_primary}` , background: `${my_icon.color.secondary}`}
    const size      = '1x';
    let icon        = null;

    if(my_icon.rotation>0)
      icon = (<FontAwesomeIcon icon={my_icon.icon} rotation={my_icon.rotation} style={my_icon.style} size={size} color={my_icon_color_primary}/>);
    else
      icon = (<FontAwesomeIcon icon={my_icon.icon} style={my_icon.style} size={size} color={my_icon_color_primary}/>);
    
    return (<div className={className} style={style}>
              {icon} 
            </div>);
  }
  //

  getNoticeData = () => {
    
    // 
    
    const { signatures } = this.state;
    if (signatures && signatures.length === 0) {
      return {};
    }
    const newNotices = signatures.map(signature => {
      
      const signatureMessage = signatureConfig.getMessage(signature);

      let newNotice = { message : {} };
      newNotice.type = 'notification'; //hack
      newNotice.created_at      = moment.unix(signature.created_at/1000).fromNow();
      newNotice.message.key     = signature._id;
      newNotice.message.avatar  = this.getCircledTypeIcon(signatureMessage.icon);
      newNotice.message.title   = signatureMessage.title;
      newNotice.message.message = signatureMessage.description;
      newNotice.signature       = signature;
      // const color     = 'rgba(255, 187, 51, 1)';
      // newNotice.extra = <Tag color={color} title={signatureMessage.description}>{signatureMessage.description}</Tag>;
      
      return newNotice;
    });
    //
    // return groupBy(newNotices, 'message.requested_type');
    return groupBy(newNotices, 'type');
    // return {'notification':newNotices};
  };
  //
  getUnreadData = (noticeData) => {
    const unreadMsg = {};
    Object.keys(noticeData).forEach(key => {
      const value = noticeData[key];
      if (!unreadMsg[key]) {
        unreadMsg[key] = 0;
      }
      if (Array.isArray(value)) {
        unreadMsg[key] = value.filter(item => !item.read).length;
      }
    });
    return unreadMsg;
  };
  //
  render() {
    const { fetchingNotices, onShownMessages } = this.props;
    const noticeData      = this.getNoticeData();
    const unreadMsg       = this.getUnreadData(noticeData);
    
    // console.log('**************** noticeData:', JSON.stringify(noticeData));
    // console.log('**************** unreadMsg:', unreadMsg)
    
    const unreadMsgCount  = Object.keys(unreadMsg).reduce((acc, key) => { acc = acc+unreadMsg[key]; return acc; } , 0)||0;
    const {formatMessage} = this.props.intl;

    return (
      <NoticeIcon
        className={'action'}
        count={unreadMsgCount}
        onItemClick={item => {
          this.changeReadState(item);
        }}
        loading={fetchingNotices||false}
        clearText={formatMessage({ id: 'component.noticeIcon.clear' })}
        viewMoreText={formatMessage({ id: 'component.noticeIcon.view-more' })}
        onClear={()=>this.handleNoticeClear(noticeData.notification)}
        onPopupVisibleChange={onShownMessages}
        onViewMore={() => message.info('Click on view more')}
        text={this.props.text}
        clearClose
      >
        <NoticeIconTab
          tabKey="notification"
          count={unreadMsg.notification}
          list={noticeData.notification}
          title={formatMessage({ id: 'component.globalHeader.notification' })}
          emptyText={formatMessage({ id: 'component.globalHeader.notification.empty' })}
          showViewMore
        />
      </NoticeIcon>
    );

  }
}

//
export default withRouter (connect(
    (state)=> ({
      accountName :                 loginRedux.accountName(state),
      menuIsCollapsed :             menuRedux.isCollapsed(state),
      signatures:                   messagingRedux.messages(state),
    }),
    (dispatch)=>({
      clearMessages:                bindActionCreators(messagingRedux.clearMessages, dispatch),
      onReadMessage:                bindActionCreators(messagingRedux.onReadMessage, dispatch),
      onShownMessages:              bindActionCreators(messagingRedux.onShownMessages, dispatch),
      collapseMenu:                 bindActionCreators(menuRedux.collapseMenu, dispatch),
      loadUnreadNotifications:      bindActionCreators(messagingRedux.loadUnreadNotifications, dispatch),
    })
)( injectIntl(GlobalHeaderRight)));
