import React, { useEffect, useState, Component } from 'react';
import { Layout } from 'antd';
import * as loginRedux from '@app/redux/models/login';

import { Link, Redirect, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as menuRedux from '@app/redux/models/menu';

export const _BlankContainer = ({ Children, isAuth, initialPath, history, isMobile }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isAuth);
  useEffect(() => {
    setIsAuthenticated(isAuth);
  }, [isAuth]);

  const [my_initialPath, setInitialPath] = useState(initialPath);
  useEffect(() => {
    setInitialPath(initialPath);
  }, [initialPath]);

  // console.log('>>>>> should redirect from HOME? :', my_initialPath, isAuthenticated);
  if (isAuthenticated && my_initialPath) {
    // console.log('.............about to redirect from HOME', my_initialPath, isAuthenticated);
    // history.replace(my_initialPath);
    // history.push({pathname: my_initialPath});
    // return <Link to={my_initialPath} />;
    return <Redirect to={my_initialPath} />;
  }

  const mobile_class = isMobile ? 'mobile_device' : '';
  console.log('>>>>> BLANK_CONTAINER::', mobile_class);
  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }} className={mobile_class}>
      {Children ? <Children /> : <div />}
    </Layout>
  );
};
//
export const BlankContainer = withRouter(
  connect(
    (state) => ({
      isMobile: menuRedux.isMobile(state),
      isAuth: loginRedux.isAuth(state),
      initialPath: loginRedux.initialPath(state),
    }),
    (dispatch) => ({})
  )(_BlankContainer)
);
