import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { withRouter } from "react-router-dom";

import { CheckOutlined } from '@ant-design/icons';

import { Menu } from 'antd';

import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login';

import HeaderDropdown from './HeaderDropdown';

import * as utils from '@app/utils/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { injectIntl } from "react-intl";

class UnvalidatedAccountWidget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      accountProfile : props.accountProfile
      , accountName  : props.accountName
    };
  }

  componentDidUpdate(prevProps, prevState) 
  {
    let new_state = {};
    if(!utils.objectsEqual(this.state.accountProfile, this.props.accountProfile) ){
        this.setState({accountProfile:this.props.accountProfile});
    }
  }

  onMenuClick = (event) => {
    const { referrer } = this.state;
    const { key }      = event;

    // if (key === 'goto_referrer' && referrer) {
    //   this.props.history.push({
    //     pathname: referrer.referrer
    //     , state: { 
    //         referrer: referrer.referrer_father
    //       }
    //   })
    // }

    console.log(this.state.accountName)
    if(!this.state.accountName)
      return;
    this.props.loadProfile(this.state.accountName);

  };

  render() {

    console.log('--email_confirmed', this.state.accountProfile && this.state.accountProfile.email_confirmed);
    if(!this.state.accountProfile || this.state.accountProfile.email_confirmed)
      return null;

    const menuHeaderDropdown = (
      <Menu className="menu" selectedKeys={[]} onClick={this.onMenuClick}>
        <Menu.Item key="unvalidated_message">
            Por favor valida tu identidad accediendo al link que enviamos a tu correo electrónico!
          </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="check_validation">
          <CheckOutlined />
          Ya lo valide!
        </Menu.Item>
      </Menu>
    );


    return (
      <HeaderDropdown overlay={menuHeaderDropdown} style={{marginRigth:16}} className="temp_alert_action">
          <span className={`action account`}>
            <FontAwesomeIcon icon="envelope" className="fa_icon"/>
          </span>
        
      </HeaderDropdown>
    );
  }
}

export default (withRouter(connect(
    (state)=> ({
      
      accountProfile:   loginRedux.accountProfile(state),
      accountName:      loginRedux.accountName(state),

    }),
    (dispatch)=>({
      loadProfile:      bindActionCreators(loginRedux.loadProfile, dispatch),
      // clearReferrer:      bindActionCreators(menuRedux.clearReferrer, dispatch),
    })
)( injectIntl(UnvalidatedAccountWidget)) ));

