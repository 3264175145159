import * as utils from '@app/utils/utils';

export const pathNames = utils.arrToObj([
  'commonMyBuckets',
  'commonPostDetails',
  'commonCreateCertificate',
  'commonUserList',
  'commonCreateUser',
  'commonUser',
  'commonPosts',
  'commonTransactions',
  'commonInfo',
  'commonDashboard',
  'commonBucketPanel',
  'commonBucketTrazability',
  'commonBucketTransactions',

  'superadminDashboard',
  'superadminRecentCertifications',
  'superadminNewCertification',

  'inProgress',
]);

const guest = [];

const owner = [];

const superadmin = [
  {
    key: pathNames.superadminDashboard,
    fileName: 'dashboard',
    area: 'superadmin',
    path: 'dashboard',
    container: 'dashboard',
    required_access: 'superadmin',
  },
  {
    key: pathNames.superadminNewCertification,
    fileName: 'new_certification',
    area: 'superadmin',
    path: 'new_certification',
    container: 'dashboard',
    required_access: 'superadmin',
  },
  {
    key: pathNames.superadminRecentCertifications,
    fileName: 'certifications',
    area: 'superadmin',
    path: 'certifications',
    container: 'dashboard',
    required_access: 'superadmin',
  },
];

const common = [
  {
    key: pathNames.commonPostDetails,
    fileName: 'post_details',
    area: 'common',
    path: 'post_details',
    container: 'dashboard',
    required_access: '*',
    param: 'postId',
  },
  {
    key: pathNames.commonCreateCertificate,
    fileName: 'create_post',
    area: 'common',
    path: 'create_post',
    container: 'dashboard',
    required_access: '*',
  },
  {
    key: pathNames.commonBucket,
    fileName: 'bucket',
    area: 'common',
    path: 'bucket',
    container: 'dashboard',
    required_access: '*',
    param: 'bucketId',
  },
  {
    key: pathNames.commonBucketPanel,
    fileName: 'bucket_panel',
    area: 'common',
    path: 'bucket_panel',
    container: 'dashboard',
    required_access: '*',
    param: 'bucketId',
  },
  {
    key: pathNames.commonBucketTrazability,
    fileName: 'bucket_trazability',
    area: 'common',
    path: 'bucket_trazability',
    container: 'dashboard',
    required_access: '*',
    param: 'bucketId',
  },
  {
    key: pathNames.commonUserList,
    fileName: 'users_list',
    area: 'common',
    path: 'users_list',
    container: 'dashboard',
    required_access: '*',
  },
  {
    key: pathNames.commonCreateUser,
    fileName: 'create_user',
    area: 'common',
    path: 'create_user',
    container: 'dashboard',
    required_access: '*',
  },
  {
    key: pathNames.commonUser,
    fileName: 'user',
    area: 'common',
    path: 'user',
    container: 'dashboard',
    required_access: '*',
    param: 'userId',
  },
  {
    key: pathNames.commonPosts,
    fileName: 'posts',
    area: 'common',
    path: 'posts',
    container: 'dashboard',
    required_access: '*',
  },
  // TODO: This 2 routes are the same, but I can´'t make the "param" optional
  // so if a don't pass a bucketId I never reach the first route
  {
    key: pathNames.commonTransactions,
    fileName: 'transactions',
    area: 'common',
    path: 'transactions',
    container: 'dashboard',
    required_access: '*',
  },
  {
    key: pathNames.commonBucketTransactions,
    fileName: 'transactions',
    area: 'common',
    path: 'bucket_transactions',
    container: 'dashboard',
    required_access: '*',
    param: 'bucketId',
  },
  {
    key: pathNames.commonInfo,
    fileName: 'info',
    area: 'common',
    path: 'info',
    container: 'dashboard',
    required_access: '*',
  },
  {
    key: pathNames.commonDashboard,
    fileName: 'dashboard',
    area: 'common',
    path: 'dashboard',
    container: 'dashboard',
    required_access: '*',
  },
];

const merged = [...guest, ...owner, ...superadmin, ...common];

export const getPath = (key) => {
  const path = (merged.find((routeItem) => routeItem.key === key) || { path: '' }).path;
  const area = (merged.find((routeItem) => routeItem.key === key) || { area: '' }).area;
  return `/${area}/${path}`;
};

const getItemTitle = (item = {}) => {
  return item.path
    ?.split('-')
    .map((obj) => utils.capitalize(obj))
    .join(' ');
};

export const getItem = (path, key) => {
  let item = null;
  if (path) item = merged.find((routeItem) => routeItem.path === path);
  else item = merged.find((routeItem) => routeItem.key === key);
  const title = getItemTitle(item);
  return { ...item, fullpath: `/${item.area}/${item.path}`, title: title };
};

export const getItemByAreaNFilename = (area, filename, itemPath) => {
  const item = merged.find(
    (routeItem) =>
      routeItem.area === area &&
      routeItem.fileName === filename &&
      (!itemPath || routeItem.path == itemPath)
  );
  const title = getItemTitle(item);
  if (title) {
    return { ...item, fullpath: `/${item.area}/${item.path}`, title: title };
  } else {
    return {};
  }
};

export const getItemByFullpath = (area, itemPath, filename) => {
  const item = merged.find(
    (routeItem) =>
      (!area || routeItem.area === area) &&
      routeItem.path == itemPath &&
      (!filename || routeItem.fileName === filename)
  );
  const title = getItemTitle(item);
  if (title) {
    return { ...item, fullpath: `/${item.area}/${item.path}`, title: title };
  } else {
    return {};
  }
};

export default merged;
