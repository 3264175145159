import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login';
import * as bucketsRedux from '@app/redux/models/buckets';
import * as siteRedux from '@app/redux/models/site';

import { withRouter } from 'react-router-dom';
import * as routesService from '@app/services/routes';

import { PageHeader } from 'antd';

import * as globalCfg from '@app/configs/global';

import { injectIntl } from 'react-intl';

import * as utils from '@app/utils/utils';

import * as components_helper from '@app/components/helper';
import BucketListWidget from '@app/components/buckets/list_widget';

class Buckets extends Component {
  constructor(props) {
    super(props);
    const bucketType = this.getBucketType();
    const filter = this.getDefaultFilter(bucketType);
    this.state = {
      routes: routesService.breadcrumbForPaths(props.location.pathname),
      loading: false,
      loadingBuckets: false,
      buckets_key: props.location.pathname,
      buckets: [],
      accountBuckets: props.accountBuckets,
      default_filter: filter,
      filter: { ...filter, page: 0, limit: globalCfg.pixivus.lists_pagesize },
      intl: {},
      isMobile: props.isMobile,
      currentBucketType: props.bucketTypes?.find((x) => x.key === bucketType) || {},
    };

    this.refreshBucketList = this.refreshBucketList.bind(this);
    this.filterBucketList = this.filterBucketList.bind(this);
  }

  getBucketType() {
    return this.props.match?.params?.bucketType || '';
  }

  getDefaultFilter = (bucketType) => {
    if (this.props.isAdmin) return { _type: bucketType };
    return { account_id: this.props.accountId, _type: bucketType };
  };

  componentDidMount() {
    this.props.loadBuckets(this.state.filter, this.state.buckets_key);

    const { formatMessage } = this.props.intl;

    let intl = {};
    intl.title = formatMessage({ id: `pages.buckets.title` });
    this.props.setCurrentPage({ title: intl.title, backButton: null, showMenu: true });
    this.setState({ intl });
  }

  componentDidUpdate(prevProps, prevState) {
    let new_state = {};
    const bucketType = this.getBucketType();
    if (prevProps.match?.params?.bucketType !== bucketType) {
      new_state = {
        ...new_state,
        filter: { ...this.state.filter, _type: bucketType },
        buckets_key: this.props.location.pathname,
      };
      this.props.loadBuckets(new_state.filter, new_state.buckets_key);
    }
    const props_buckets = this.props.buckets[this.state.buckets_key];

    if (
      prevProps.match?.params?.bucketType !== bucketType ||
      prevProps.bucketTypes?.length !== this.props.bucketTypes?.length
    ) {
      // if bucketType charges or bucketTypes gets updated
      new_state = {
        ...new_state,
        currentBucketType: this.props.bucketTypes?.find((type) => type.key === bucketType) || {},
      };
    }

    if (!utils.arraysEqual(this.state.buckets, props_buckets)) {
      new_state = { ...new_state, buckets: props_buckets };
    }

    if (this.state.loadingBuckets !== this.props.loadingBuckets) {
      new_state = { ...new_state, loadingBuckets: this.props.loadingBuckets };
    }

    if (!this.props.isAdmin && this.state.default_filter.account_id !== this.props.accountId) {
      new_state = { ...new_state, default_filter: { account_id: this.props.accountId } };
    }

    if (!utils.arraysEqual(this.state.accountBuckets, this.props.accountBuckets)) {
      new_state = { ...new_state, accountBuckets: this.props.accountBuckets };
    }

    if (this.state.isMobile !== this.props.isMobile) {
      new_state = { ...new_state, isMobile: this.props.isMobile };
    }

    if (Object.keys(new_state).length > 0) this.setState(new_state);
  }

  gotoBucket = (_type, item) => {
    this.props.setLastRootMenuFullpath(this.props.location.pathname);

    if (_type == 'bucket') {
      this.props.history.push({
        pathname: `/common/bucket/${item._id}`,
        state: { bucketId: item._id },
      });
      return;
    }

    if (_type == 'posts') {
      this.props.history.push({
        pathname: `/common/bucket_transactions/${item._id}`,
        state: { bucketId: item._id },
      });
    }

    if (_type == 'new_post') {
      this.props.history.push({
        pathname: `/common/create_post/${item._id}`,
        state: { bucketId: item._id },
      });
    }
  };

  refreshBucketList = () => {
    this.props.loadBuckets(this.state.filter, this.state.buckets_key);
  };

  filterBucketList = (filter) => {
    const that = this;
    const { default_filter } = this.state;
    const _filter = { ...filter, ...default_filter };
    this.setState({ filter: _filter }, () => {
      that.props.loadBuckets(_filter, that.state.buckets_key);
    });
  };

  loadMoreBuckets = (pagination) => {
    const that = this;
    let _filter = this.state.filter;
    const { default_filter } = this.state;
    const _count = this.state.buckets.length;
    const _limit = parseInt(_filter.limit);
    _filter.page = Math.ceil(_count / _limit);
    const __filter = { ..._filter, ...default_filter };

    this.setState({ filter: __filter }, () => {
      that.props.loadMoreBuckets(__filter, that.state.buckets_key);
    });
  };

  render() {
    const { buckets, loading, loadingBuckets, isMobile } = this.state;

    return (
      <>
        {!isMobile && <PageHeader title={this.state.currentBucketType.name} />}

        <div className="styles buckets_list_container">
          <BucketListWidget
            the_key="bucketlistwidget"
            buckets={buckets}
            bucketType={this.state.currentBucketType}
            loading={loading || loadingBuckets}
            loadMoreCallback={this.loadMoreBuckets}
            callback={this.gotoBucket}
            refresh_callback={this.refreshBucketList}
            filter_callback={this.filterBucketList}
          />
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      accountId: loginRedux.accountId(state),
      accountBuckets: loginRedux.accountBuckets(state),
      accountName: loginRedux.accountName(state),
      accountType: loginRedux.accountType(state),
      isAdmin: loginRedux.isAdmin(state),
      buckets: bucketsRedux.buckets(state),
      loadingBuckets: bucketsRedux.isLoading(state),
      isMobile: menuRedux.isMobile(state),
      bucketTypes: siteRedux.bucketTypes(state),
    }),
    (dispatch) => ({
      setCurrentPage: bindActionCreators(menuRedux.setCurrentPage, dispatch),
      loadMoreBuckets: bindActionCreators(bucketsRedux.loadMore, dispatch),
      loadBuckets: bindActionCreators(bucketsRedux.load, dispatch),
      setLastRootMenuFullpath: bindActionCreators(menuRedux.setLastRootMenuFullpath, dispatch),
    })
  )(injectIntl(Buckets))
);
